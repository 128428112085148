<template>
  <v-card flat v-if="scanGaugeKit" class="rounded-10">
    <v-card-title class="pb-0 text-center primary--text">
      <v-row>
        <v-col class="text-center primary--text">
          <h3>
            {{ scanGaugeKit.name }}
            <v-btn
              fab
              elevation="0"
              x-small
              dark
              color="grey"
              class="right-corner"
              @click="collapsed = !collapsed"
            >
              <v-icon>
                {{ collapsed ? "mdi-chevron-down" : "mdi-chevron-up" }}
              </v-icon>
            </v-btn>
          </h3>
          <span style="font-size: 20px">{{ scanGaugeKit.description }}</span>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <div class="text-center mt-5 title-font">
        <span class="pricing-card-price">
          ${{ scanGaugeKit.price.toLocaleString() }}
        </span>
        (One Off Payment)
      </div>
      <v-expand-transition>
        <v-row v-if="!collapsed" class="mt-5" justify="center">
          <v-col cols="auto">
            <v-img
              :src="require('@/assets/sgk-gen2.png')"
              contain
              class="mb-3"
              width="180px"
            ></v-img>
          </v-col>
          <v-col cols="auto">
            <div><strong>Generation 2.0 Scan Gauge Kit:</strong></div>
            <strong> Universal (AA)* </strong>
            <ul>
              <li v-for="(feature, index) in features" :key="index">
                <span>
                  {{ feature }}
                </span>
              </li>
            </ul>
          </v-col>
        </v-row>
      </v-expand-transition>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "ScanGaugeKit",

  props: ["scanGaugeKit"],

  data() {
    return {
      collapsed: false
    }
  },

  computed: {
    features() {
      if (!this.scanGaugeKit.features.length) {
        return []
      }
      return this.scanGaugeKit.features
    }
  }
}
</script>
