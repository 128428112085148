<template>
  <v-container fluid class="pa-0">
    <v-container fluid v-if="!selectedOrder" class="pa-4">
      <v-skeleton-loader type="heading" class="my-5" />
      <v-row>
        <v-col cols="4">
          <v-skeleton-loader type="card" />
        </v-col>
        <v-col cols="4">
          <v-skeleton-loader type="card" />
        </v-col>
        <v-col cols="4">
          <v-skeleton-loader type="card" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-skeleton-loader type="card" />
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid v-if="!initialising" class="pa-0">
      <v-row>
        <v-col cols="12">
          <v-toolbar flat height="32" class="mb-4" color="secondary" dark>
            <v-icon small class="mr-2">mdi-inbox-outline</v-icon
            >{{ selectedOrder.order_number }}
            <v-spacer />
            <v-icon small class="mr-2">mdi-office-building-outline</v-icon>
            {{ selectedOrder.client_name }}
            <v-spacer />
            <v-icon small class="mr-2">mdi-account-outline</v-icon>
            {{ selectedOrder.patient_reference }}
          </v-toolbar>
          <v-toolbar flat class="rounded-20 mx-4">
            <v-tabs align-with-title v-model="tabIndex">
              <v-tab @click="changeTab('overview')">Overview</v-tab>
              <v-tab @click="changeTab('library')">
                <v-icon
                  v-if="!selectedOrder.associated"
                  color="warning"
                  small
                  class="mr-2"
                >
                  mdi-alert-circle
                </v-icon>
                <v-icon
                  v-if="selectedOrder.associated"
                  color="success"
                  small
                  class="mr-2"
                >
                  mdi-check-circle
                </v-icon>
                Library Association
              </v-tab>
              <v-tab @click="changeTab('export')">
                <v-icon
                  v-if="!selectedOrder.associated"
                  color="error"
                  small
                  class="mr-2"
                >
                  mdi-alert
                </v-icon>
                <v-icon
                  v-if="selectedOrder.statuses.includes('exported')"
                  color="success"
                  small
                  class="mr-2"
                >
                  mdi-check-circle
                </v-icon>
                Export
              </v-tab>
              <v-tab @click="changeTab('order')">
                <v-icon
                  v-if="
                    !selectedOrder.associated ||
                    !selectedOrder.statuses.includes('exported')
                  "
                  color="error"
                  small
                  class="mr-2"
                >
                  mdi-alert
                </v-icon>
                Order
              </v-tab>
              <v-tab @click="changeTab('order-form')">Order Form</v-tab>
              <v-tab @click="changeTab('chat')">Chat &amp; Notes</v-tab>
              <v-tab @click="changeTab('scans-files')">Scans &amp; Files</v-tab>
              <v-spacer />
              <v-btn class="my-auto mr-2" icon @click="closeOrder">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-tabs>
          </v-toolbar>
        </v-col>
      </v-row>

      <v-card
        v-if="tab === 'overview'"
        flat
        height="100%"
        width="100%"
        class="transparent"
      >
        <v-row>
          <v-col cols="4">
            <PreviewComponent class="mb-5" />
            <OrderForm @fullmode="changeTab('order-form')" />
          </v-col>
          <v-col cols="4">
            <AlignmentScan
              v-if="!selectedOrder.associated"
              @toggleViewLibAssociation="changeTab('library')"
            />
            <AssociationPreview
              v-if="selectedOrder.associated"
              @redoAnalysis="redoAnalysis"
            />
            <v-card flat class="rounded-20 mb-5 mt-4">
              <v-card-title class="title-font"> SCAN GAUGE KIT </v-card-title>
              <v-card-text>
                <v-card flat max-width="256" class="mx-auto">
                  <v-img
                    height="75"
                    contain
                    :src="require('@/assets/sgk-gen2.png')"
                  />
                  <h3 class="text-center mt-2">
                    {{ prettyKitName(selectedOrder.kit_box_id) }}
                  </h3>
                </v-card>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="4">
            <CaseChat @fullmode="changeTab('chat')" class="mb-4" />
            <OpenForwardedOrders
              @openOrder="changeTab('order', 'forwardedOrder', ...arguments)"
            />
          </v-col>
        </v-row>
      </v-card>

      <v-card
        v-if="tab === 'library'"
        flat
        height="100%"
        width="100%"
        class="transparent"
      >
        <LibraryAssociation />
      </v-card>

      <v-card
        v-if="tab === 'export'"
        flat
        height="100%"
        width="100%"
        class="transparent"
      >
        <ExportComponent v-if="selectedOrder.associated" />
        <div v-if="!selectedOrder.associated">
          <v-card flat class="ma-4 rounded-20">
            <v-card-title class="title-font"> EXPORT ABUTMENTS </v-card-title>
          </v-card>
          <v-card flat class="rounded-20 ma-4" height="70vh">
            <v-card-text>
              <div class="text-center black--text">
                <div class="mt-10 mb-5">
                  <v-icon size="32" color="error">mdi-alert</v-icon>
                </div>
                <div>
                  You must perform a library association before exporting
                  abutment positions or ordering products from Osteon.
                </div>
                <div class="mt-10">
                  <v-btn text class="btn-primary" @click="changeTab('library')">
                    <v-icon class="mr-2">$gauge</v-icon>
                    START LIBRARY ASSOCIATION
                  </v-btn>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-card>

      <v-card
        v-if="tab === 'order'"
        flat
        height="100%"
        width="100%"
        class="transparent"
      >
        <OrderComponent
          v-if="
            selectedOrder.associated &&
            selectedOrder.statuses.includes('exported')
          "
        />
        <div v-if="!selectedOrder.associated">
          <v-card flat class="ma-4 rounded-20">
            <v-card-title class="title-font"> ORDER </v-card-title>
          </v-card>
          <v-card flat class="rounded-20 ma-4" height="70vh">
            <v-card-text>
              <div class="text-center black--text">
                <div class="mt-10 mb-5">
                  <v-icon size="32" color="error">mdi-alert</v-icon>
                </div>
                <div>
                  You must perform a library association before exporting
                  abutment positions or ordering products from Osteon.
                </div>
                <div class="mt-10">
                  <v-btn text class="btn-primary" @click="changeTab('library')">
                    <v-icon class="mr-2">$gauge</v-icon>
                    START LIBRARY ASSOCIATION
                  </v-btn>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>
        <div
          v-if="
            selectedOrder.associated &&
            !selectedOrder.statuses.includes('exported')
          "
        >
          <v-card flat class="ma-4 rounded-20">
            <v-card-title class="title-font"> ORDER FROM OSTEON </v-card-title>
          </v-card>
          <v-card flat class="rounded-20 ma-4" height="70vh">
            <v-card-text>
              <div class="text-center black--text">
                <div class="mt-10 mb-5">
                  <v-icon size="32" color="error">mdi-alert</v-icon>
                </div>
                <div>
                  You must export abutment positions before ordering products
                  from Osteon.
                </div>
                <div class="mt-10">
                  <v-btn text class="btn-primary" @click="changeTab('export')">
                    <v-icon class="mr-2" large>$implantexportDark</v-icon>
                    EXPORT ABUTMENT POSITIONS
                  </v-btn>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-card>

      <v-card
        v-if="tab === 'order-form'"
        flat
        height="100%"
        width="100%"
        class="transparent"
      >
        <OrderForm :fullmode="true" />
      </v-card>

      <v-card
        v-if="tab === 'chat'"
        flat
        height="100%"
        width="100%"
        class="transparent"
      >
        <CaseChat class="ma-4" :fullmode="true" />
      </v-card>

      <v-card
        v-show="tab === 'scans-files'"
        flat
        height="100%"
        width="100%"
        class="transparent"
      >
        <ScansAndFiles />
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import client from "@/lib/ApiClient"

import PreviewComponent from "./OrderView/PreviewComponent"
import OrderForm from "./OrderView/OrderForm"
import AlignmentScan from "./OrderView/AlignmentScan"
import AssociationPreview from "./OrderView/AssociationPreview"
import CaseChat from "@/components/shared/CaseChat"
import LibraryAssociation from "./OrderView/LibraryAssociation"
import ExportComponent from "./OrderView/ExportComponent"
import OrderComponent from "./OrderView/OrderComponent"
import ScansAndFiles from "./OrderView/ScansAndFiles"
import OpenForwardedOrders from "@/components/shared/OpenForwardedOrders"

export default {
  name: "OrderView",

  props: ["uid"],

  components: {
    PreviewComponent,
    OrderForm,
    AlignmentScan,
    AssociationPreview,
    CaseChat,
    LibraryAssociation,
    ExportComponent,
    OrderComponent,
    ScansAndFiles,
    OpenForwardedOrders
  },

  data() {
    return {
      initialising: true,
      tab: "",
      tabs: [
        "overview",
        "library",
        "export",
        "order",
        "order-form",
        "chat",
        "scans-files",
        "order-form"
      ]
    }
  },

  computed: {
    ...mapGetters([
      "selectedOrder",
      "orderForwardedOrders",
      "selectedForwardedOrder",
      "selectedOrderExports"
    ]),

    tabIndex: {
      get() {
        return this.tabs.indexOf(this.tab)
      },

      set() {}
    }
  },

  watch: {
    uid(value) {
      if (!value) return
      this.$router.push({ query: { tab: "overview" } })
      this.fetchOrder()
    },

    $route(value) {
      this.tab = value.query.tab
    }
  },

  methods: {
    ...mapActions([
      "setSelectedOrder",
      "setOrderFiles",
      "setOrderMessages",
      "setOrderForwardedOrders",
      "setSelectedForwardedOrder"
    ]),

    prettyKitName(kit_box_id) {
      const parts = kit_box_id.split("_")
      return `${parts[parts.length - 1]} (${parts[parts.length - 2]})`
    },

    redoAnalysis() {
      client.instance.orders.redoAnalysis(this.uid).then(() => {
        this.fetchOrder()
        this.changeTab("library")
      })
    },

    changeTab(tab, param = null, paramContent = null) {
      if (this.$route.query.tab === tab) return

      const params = param && paramContent ? { [param]: paramContent } : null

      this.setSelectedForwardedOrder(null)

      this.$router.push({
        query: { tab: tab },
        params
      })
    },

    async fetchOrder() {
      this.setSelectedOrder(await client.instance.orders.getOrder(this.uid))
    },

    async fetchForwardedOrders() {
      this.setOrderForwardedOrders(
        await client.instance.orders.getForwardedOrders(this.selectedOrder.uid)
      )
    },

    async initialise() {
      this.initialising = true
      this.setOrderFiles(null)
      this.setOrderMessages(null)
      await this.fetchOrder()
      this.initialising = false
    },

    closeOrder() {
      this.setOrderFiles(null)
      this.setOrderMessages(null)
      this.$router.push({ name: "inboxPage" })
    }
  },

  async mounted() {
    if (!this.uid && !this.selectedOrder) return
    if (!this.selectedOrder) await this.initialise()
    await this.fetchForwardedOrders()
    this.initialising = false
    if (!this.$route.query.tab) {
      this.$router.push({ query: { tab: "overview" } })
    }
    this.tab = this.$route.query.tab
  }
}
</script>
