<template>
  <div>
    <v-card v-if="workflowStep < 3" class="rounded-20 ma-4" flat>
      <v-row class="ma-0">
        <v-col cols="8" class="pa-0">
          <v-card-title class="title-font">
            <v-icon large class="mr-2">$milledbar</v-icon> MILLED BAR ORDER
            <v-progress-circular
              indeterminate
              v-if="loading"
              size="32"
              class="ml-5"
            />
          </v-card-title>
          <v-card-text v-if="workflowStep === 2">
            Continue your order by completing the necessary options.
          </v-card-text>
        </v-col>
        <v-col cols="4" class="pa-0">
          <v-card-text class="fill-width pa-0 d-flex justify-center">
            <v-img
              :src="require('@/assets/milled-bar-only.png')"
              class="ma-4"
              contain
              max-width="200"
            />
          </v-card-text>
        </v-col>
      </v-row>
      <div v-if="workflowStep === 1 && this.ready">
        <v-card-text class="black--text py-0">
          <v-stepper vertical class="elevation-0 py-0" v-model="currentStep">
            <v-stepper-step
              step="1"
              :complete="exportComplete"
              :color="exportComplete ? 'success' : 'primary'"
            >
              Export Abutment Position
            </v-stepper-step>
            <v-stepper-content step="1">
              <p>
                In order to order a milled bar you will need to supply us with a
                tooth design.
              </p>
              <p>
                You have yet to export abutment positions for this case. Please
                continue to export abutment positions.
              </p>
              <v-btn class="btn-primary" @click="$emit('toExport')">
                Export Abutment Position
              </v-btn>
            </v-stepper-content>
            <v-stepper-step
              step="2"
              :complete="exportComplete"
              :color="exportComplete ? 'success' : 'primary'"
            >
              Complete Tooth Design
            </v-stepper-step>
            <v-stepper-content step="2" />
            <v-stepper-step step="3"> Order Milled Bar </v-stepper-step>
            <v-stepper-content step="3">
              <v-btn
                class="btn-success white--text my-4 mx-4"
                @click="handleContinue"
              >
                Order Bar Now
                <v-icon class="ml-3" size="40"> $milledbarDark </v-icon>
              </v-btn>
            </v-stepper-content>
            <v-stepper-step step="4"> Approve Bar Design </v-stepper-step>
            <v-stepper-content step="4" />
            <v-stepper-step step="5"> Bar Production </v-stepper-step>
            <v-stepper-content step="5" />
            <v-stepper-step step="6"> Bar Delivery </v-stepper-step>
            <v-stepper-content step="6" />
          </v-stepper>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn class="btn-error" @click="$emit('back')"> Back </v-btn>
        </v-card-actions>
      </div>
      <div v-if="workflowStep == 2">
        <ForwardedOrderOptions
          @back="handleStepChange(--workflowStep)"
          @next="handleStepChange(++workflowStep)"
        />
      </div>
    </v-card>

    <v-row v-if="workflowStep == 3">
      <v-col cols="6">
        <v-card class="rounded-20 fill-height ml-4" flat>
          <v-row class="ma-0">
            <v-col cols="8" class="pa-0">
              <v-card-title class="title-font">
                <v-icon large class="mr-2">$milledbar</v-icon> MILLED BAR ORDER
              </v-card-title>
            </v-col>
            <v-col cols="4" class="pa-0">
              <v-card-text class="d-flex justify-center fill-width pb-0">
                <v-img
                  :src="require('@/assets/milled-bar-only.png')"
                  class="mx-4"
                  contain
                  max-width="200"
                  position="center right"
                />
              </v-card-text>
            </v-col>
          </v-row>
          <v-card-text class="pt-0">
            <OrderSummary />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <CaseChat v-if="workflowStep == 3" class="fill-height" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import client from "@/lib/ApiClient"
import CaseChat from "@/components/shared/CaseChat.vue"
import OrderSummary from "./MilledBarOrder/OrderSummary.vue"
import ForwardedOrderOptions from "./MilledBarOrder/ForwardedOrderOptions.vue"

export default {
  name: "MilledBarOrder",

  components: {
    CaseChat,
    OrderSummary,
    ForwardedOrderOptions
  },

  data() {
    return {
      workflowStep: 1,
      loading: false,
      lineItem: {
        description: "Milled Bar",
        amount: "2 000",
        currency: "AUD",
        quantity: 1,
        product_code: "BAR",
        quota_code: "bars"
      },
      ready: false
    }
  },

  computed: {
    ...mapGetters([
      "selectedOrder",
      "selectedForwardedOrder",
      "forwardedOrderForm",
      "selectedOrderExports"
    ]),

    exportComplete() {
      return !!this.selectedOrderExports.length
    },

    currentStep() {
      return this.exportComplete ? 3 : 1
    },

    acquisitionMethod() {
      return this.acquisitionStrategy?.find((s) => s.product_code === "BAR")
        .method
    }
  },

  methods: {
    ...mapActions([
      "setSelectedForwardedOrder",
      "setForwardedOrderForm",
      "setCurrentQuotas",
      "triggerReload"
    ]),

    handleStepChange(step) {
      this.workflowStep = step
    },

    async createForwardedOrder() {
      const forwardedOrder = await client.instance.orders.createForwardedOrder(
        this.selectedOrder.uid,
        this.forwardedOrderForm
      )
      this.setSelectedForwardedOrder(forwardedOrder)
    },

    async handleContinue() {
      this.loading = true
      await this.createForwardedOrder()
      this.loading = false
      return this.workflowStep++
    }
  },

  async mounted() {
    if (this.selectedForwardedOrder) {
      if (this.selectedForwardedOrder.submitted_at) {
        this.handleStepChange(3)
      } else {
        this.handleStepChange(2)
      }
    } else {
      let implants = [
        ...this.selectedOrder.responses.find(
          (item) => item.widget === "tooth_picker"
        ).value
      ]
      this.setForwardedOrderForm({
        ...this.forwardedOrderForm,
        implants: implants
      })
    }
    this.ready = true
  }
}
</script>
