<template>
  <v-card flat>
    <v-card-title class="title-font">
      CONNECTION TYPE
      <v-spacer />
      <div v-if="showErrors && !connectionType" class="error--text text-body-2">
        <v-icon color="error" class="mr-2">mdi-close-circle</v-icon>
        <span> Please select a Connection Type </span>
      </div>
    </v-card-title>
    <v-card-text>
      Choose which type of connection the file will be designed to.
    </v-card-text>
    <v-row class="mx-1 mb-2">
      <v-col cols="6">
        <v-card
          outlined
          :class="[
            'd-flex flex-row rounded-10 pa-2 fill-height btn-hover',
            { 'btn-selected': connectionType === 'direct' }
          ]"
          v-ripple
          style="user-select: none; border-width: 2px"
          @click="handleButtonSelect('direct')"
        >
          <v-img
            contain
            :src="require('@/assets/screw-comparison-rosen.png')"
          ></v-img>
          <v-card-text class="fill-height d-flex flex-column justify-center">
            <p class="mb-0">
              <strong>Direct to Multi-Unit Abutment</strong>
            </p>
            <p class="mb-0">with Tapered Screw seat (Rosen)</p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card
          outlined
          :class="[
            'd-flex flex-row rounded-10 pa-2 fill-height btn-hover ',
            { 'btn-selected': connectionType === 'tiBase' }
          ]"
          v-ripple
          style="user-select: none; border-width: 2px"
          @click="handleButtonSelect('tiBase')"
        >
          <v-img
            contain
            :src="require('@/assets/screw-comparison-Ti-base.png')"
          ></v-img>
          <v-card-text class="fill-height d-flex flex-column justify-center">
            <p class="mb-0">
              <strong>Ti-Base to Multi-Unit Abutment</strong>
            </p>
            <p class="mb-0">with prosthetic screw seat</p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "ConnectionTypeSelector",

  props: ["order", "value"],

  inject: ["form"],

  data() {
    return {
      showErrors: false
    }
  },

  computed: {
    connectionType: {
      get() {
        return this.value
      },
      set(value) {
        this.showErrors = false
        this.$emit("input", value)
      }
    }
  },

  methods: {
    handleButtonSelect(selection) {
      if (this.connectionType === selection) return (this.connectionType = null)
      this.connectionType = selection
    },

    validate() {
      if (!this.connectionType) this.showErrors = true
      return !!this.connectionType
    },

    reset() {
      this.connectionType = null
    }
  },

  created() {
    this.form.register(this)
  }
}
</script>
