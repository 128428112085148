<template>
  <div>
    <v-btn
      block
      outlined
      color="success"
      class="rounded-10 mb-2"
      @click="dialog = true"
    >
      Approve
    </v-btn>
    <v-dialog v-model="dialog" max-width="600px" content-class="rounded-20">
      <v-card>
        <v-card-title>IMPORTANT: APPROVAL</v-card-title>
        <v-card-text>
          Only continue if your case requires no further changes, it will
          continue to production.
          <v-progress-circular indeterminate v-if="loading" />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn class="btn-primary" @click="approve">Confirm</v-btn>
          <v-btn elevation="0" class="rounded-10" @click="dialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import client from "@/lib/ApiClient"
export default {
  props: ["approval"],
  data() {
    return {
      loading: false,
      dialog: false
    }
  },

  watch: {
    orderApprovals() {
      if (this.loading) {
        this.loading = false
        this.dialog = false
      }
    }
  },

  computed: {
    ...mapGetters(["orderApprovals"])
  },

  methods: {
    ...mapActions(["triggerReload"]),
    async approve() {
      this.loading = true
      await client.instance.messages.approvalAction(
        this.approval.uid,
        "approve"
      )
      this.triggerReload("approvals")
    }
  }
}
</script>
