<template>
  <v-card flat>
    <v-card-title class="px-0">Scan Gauge Kits (Required)</v-card-title>
    <v-row>
      <v-row v-if="initialising" class="my-5">
        <v-col class="text-center">
          <v-progress-circular size="64" indeterminate />
        </v-col>
      </v-row>
      <v-col
        cols="12"
        class="text-center"
        v-if="!initialising && scanGaugeKits?.length === 0"
      >
        You currently have no scan gauge kits associated to your account.
      </v-col>
      <v-col cols="2" v-for="(kit, index) in scanGaugeKits || []" :key="index">
        <v-card
          flat
          class="text-center rounded-10 mx-auto"
          :class="{ 'btn-primary': kit.kit_box_id === scanGaugeKit }"
          @click="scanGaugeKit = kit.kit_box_id"
        >
          <v-img contain :src="require('@/assets/sgk-gen2.png')" />
          <small>
            {{ prettyKitName(kit.kit_box_id) }}
          </small>
        </v-card>
      </v-col>
      <v-col
        cols="2"
        v-if="!initialising && isOwner && (kitQuotasRemaining || upgradeable)"
      >
        <v-btn
          width="100%"
          min-height="142px"
          height="100%"
          class="rounded-10 elevation-0"
          style="text-transform: none"
          @click="handleOrder"
        >
          <template v-slot:default>
            <div class="d-flex flex-column" style="opacity: 0.6">
              <v-icon size="36" class="mb-2">mdi-plus</v-icon>
              <span class="text-body-2">Purchase</span>
            </div>
          </template>
        </v-btn>
      </v-col>
    </v-row>
    <PurchaseDialog
      v-model="dialog"
      :item="lineItem"
      :loading="loading"
      @confirm="orderScanGaugeKit"
    />
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import { deepEqual } from "@/utils"
import client from "@/lib/ApiClient"
import PurchaseDialog from "@/components/shared/PurchaseDialog.vue"

export default {
  name: "ScanGaugeKitSelector",

  props: ["value"],

  components: { PurchaseDialog },

  data() {
    return {
      dialog: false,
      initialising: false,
      loading: false,
      invoice_uid: null,
      lineItem: {
        description: "Scan Gauge Kit",
        amount: "3 995",
        currency: "AUD",
        quantity: 1,
        product_code: "GAUGEKIT-GEN2",
        quota_code: "registered_kits"
      }
    }
  },

  watch: {
    scanGaugeKits(val, prevVal) {
      if (val && !deepEqual(val, prevVal)) {
        this.initialising = false
        this.loading = false
        if (this.dialog) this.dialog = false
      }
    }
  },

  computed: {
    ...mapGetters([
      "isOwner",
      "currentQuotas",
      "plans",
      "subscription",
      "scanGaugeKits"
    ]),

    scanGaugeKit: {
      get() {
        return this.value
      },

      set(value) {
        this.$emit("input", value)
      }
    },

    kitQuotasRemaining() {
      const remaining_kit_quotas = this.currentQuotas?.find(
        (q) => q.quota_code === "registered_kits"
      )?.current
      return remaining_kit_quotas
    },

    upgradeable() {
      const currentPlan = this.plans.find(
        (p) => p.code === this.subscription.plan_code
      )
      const upgradeable = this.plans.some(
        (p) => p.subscription_price > currentPlan.subscription_price
      )
      return upgradeable
    }
  },

  methods: {
    ...mapActions(["setCurrentQuotas", "triggerReload"]),

    // async fetchGaugeKits() {
    //   this.initialising = true
    //   this.scanGaugeKits = await client.gateway.gaugeKits.fetchScanGaugeKits()
    //   this.initialising = false
    // },

    prettyKitName(kit_box_id) {
      const parts = kit_box_id.split("_")
      return `${parts[parts.length - 1]} (${parts[parts.length - 2]})`
    },

    handleOrder() {
      if (!this.kitQuotasRemaining)
        this.$router.push({ name: "profilePage", params: { change: true } })

      this.dialog = true
    },

    async poll() {
      if (!this.invoice_uid) return
      const invoice_paid = await client.gateway.billing.checkInvoice(
        this.invoice_uid
      )
      if (invoice_paid) {
        this.triggerReload("quotas")
        this.triggerReload("scanGaugeKits")
        // this.dialog = false
      } else setTimeout(this.poll, 500)
    },

    async orderScanGaugeKit(paymentMethodId) {
      this.loading = true
      try {
        this.invoice_uid = await client.gateway.gaugeKits.orderScanGaugeKit(
          paymentMethodId
        )
      } catch {
        // Error states?
        console.error("Failed to order Scan Gauge Kit.")
        this.loading = false
        return
      }
      this.poll()
    }
  },

  mounted() {
    if (this.scanGaugeKits === null) this.initialising = true
    this.triggerReload("scanGaugeKits")
  }
}
</script>
