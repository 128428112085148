<template>
  <vue-tel-input-vuetify
    @input="handlePhone"
    v-model="phoneNumber"
    outlined
    :label="label"
    dense
    :rules="rules"
    class="mb-n1"
    :defaultCountry="country"
    :disabledFetchingCountry="true"
  />
</template>

<style>
.vue-tel-input-vuetify .country-code {
  width: 45px;
  margin-right: 1em;
}
</style>

<script>
export default {
  name: "PhoneInput",

  inject: ["form"],

  props: ["value", "label", "country"],

  data() {
    return {
      phoneValid: false,
      rules: [() => this.phoneValid || "This phone number is invalid"]
    }
  },

  computed: {
    phoneNumber: {
      get() {
        return this.value
      },

      set(value) {
        this.$emit("input", value)
      }
    }
  },

  methods: {
    validate() {
      return this.phoneValid
    },

    reset() {
      this.phoneNumber = null
      this.phoneValid = true
    },

    resetValidation() {
      this.phoneNumber = null
      this.phoneValid = true
    },

    handlePhone(input, { number, valid }) {
      this.phoneNumber = number.international || ""
      this.phoneValid = valid
    }
  },

  created() {
    this.form.register(this)
  }
}
</script>
