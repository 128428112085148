<template>
  <v-row class="my-0">
    <v-col class="py-0">
      <v-card
        flat
        class="rounded-20 ma-0 mx-4 mt-4 d-flex flex-column"
        height="65vh"
      >
        <v-row>
          <v-col cols="4" align="center" class="my-10">
            <DesignOptions
              :loading="loading"
              @chooseOrder="$emit('chooseOrder', 'DESIGN')"
            />
          </v-col>

          <v-col cols="4" align="center" class="my-10">
            <BarOptions
              :loading="loading"
              @chooseOrder="$emit('chooseOrder', 'BAR')"
            />
          </v-col>

          <v-col cols="4" align="center" class="my-10">
            <DesignAndBarOptions :loading="loading" />
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer />
          <v-btn class="btn-error" elevation="0" @click="cancel">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col v-if="orderForwardedOrders?.length" cols="4" class="py-0">
      <ExistingAndPartialOrders />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import ExistingAndPartialOrders from "./ChooseOrder/ExistingAndPartialOrders.vue"
import DesignOptions from "./ChooseOrder/DesignOptions.vue"
import BarOptions from "./ChooseOrder/BarOptions.vue"
import DesignAndBarOptions from "./ChooseOrder/DesignAndBarOptions.vue"

export default {
  name: "ChooseOrder",

  components: {
    ExistingAndPartialOrders,
    DesignOptions,
    BarOptions,
    DesignAndBarOptions
  },

  data() {
    return {
      partialOrders: [],
      loading: false
    }
  },

  computed: {
    ...mapGetters([
      "selectedOrder",
      "selectedForwardedOrder",
      "forwardedOrderForm",
      "orderForwardedOrders"
    ])
  },

  methods: {
    ...mapActions(["setSelectedForwardedOrder", "triggerReload"]),

    cancel() {
      this.$emit("cancelled")
    }
  }
}
</script>
