<template>
  <v-card flat class="card-bg rounded-20">
    <v-toolbar class="transparent" flat dense>
      <v-toolbar-title class="title-font">
        PAYMENT METHODS {{ currentTenant.default_payment_id }}
      </v-toolbar-title>
    </v-toolbar>

    <v-card flat class="mx-4 rounded-10">
      <v-data-table
        :headers="headers"
        :items="paymentMethods || []"
        hide-default-footer
        class="rounded-10"
      >
        <template v-slot:no-data v-if="loading">
          <v-progress-circular indeterminate />
        </template>
        <template v-slot:[`item.brand`]="{ item }">
          <v-icon v-if="item.brand === 'visa'">$visa</v-icon>
          <v-icon v-else-if="item.brand === 'mastercard'">$mastercard</v-icon>
          <v-icon v-else-if="item.brand === 'amex'">$amex</v-icon>
          <v-icon v-else>mdi-credit-card</v-icon>
          <strong class="mx-2">
            {{ capitalise(item.brand) }}
          </strong>
          <v-chip v-if="item.default" color="primary" label x-small>
            Default Payment
          </v-chip>
          <v-btn
            v-if="!item.default"
            :disabled="loading"
            text
            label
            x-small
            plain
            @click="handleConfirm(item, 'setDefault')"
          >
            Set as default
          </v-btn>
        </template>
        <template v-slot:[`item.last4`]="{ item }">
          <strong> **** **** **** {{ item.last4 }} </strong>
        </template>
        <template v-slot:[`item.expiry`]="{ item }">
          Expires
          {{
            new Date(0, item.exp_month - 1).toLocaleString("en-US", {
              month: "short"
            })
          }}
          {{ item.exp_year }}
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-icon
            :disabled="loading"
            small
            @click="handleConfirm(item, 'delete')"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>

      <DeleteDialog
        v-model="dialogs.delete"
        :loading="loading"
        @remove="deletePayment()"
      />
      <SetDefaultDialog
        v-model="dialogs.setDefault"
        :loading="loading"
        @setDefault="setDefaultPayment()"
      />
      <v-dialog
        v-model="dialogs.add"
        max-width="500px"
        content-class="rounded-20"
      >
        <v-card flat>
          <v-card-title class="off">
            <span class="text-h5">Add a card</span>
          </v-card-title>

          <v-card-text class="py-5">
            <v-container>
              <v-row>
                <v-col cols="12" class="px-0">
                  <StripeElementCard
                    ref="elementRef"
                    :pk="pk"
                    @token="tokenCreated"
                    @error="loading = false"
                    hidePostalCode
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions class="off">
            <v-spacer></v-spacer>
            <v-btn
              class="btn-error"
              text
              :disabled="loading"
              @click="dialogs.add = false"
            >
              Cancel
            </v-btn>
            <v-btn class="btn-primary" :loading="loading" @click="addCard">
              Add card
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
    <v-card-actions class="mx-2">
      <div class="text-caption">
        The default payment method will be charged each billing cycle.
      </div>
      <v-spacer></v-spacer>
      <v-btn @click="dialogs.add = true" class="btn-primary"> Add card </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import { capitalise } from "@/utils"
import client from "@/lib/ApiClient"
import DeleteDialog from "./PaymentMethods/DeleteDialog.vue"
import SetDefaultDialog from "./PaymentMethods/SetDefaultDialog.vue"
import { StripeElementCard } from "@vue-stripe/vue-stripe"

export default {
  name: "PaymentMethods",

  components: { StripeElementCard, DeleteDialog, SetDefaultDialog },

  watch: {
    paymentMethods(val) {
      if (val) this.loading = false
    },
    dialogs({ add, delete: del, setDefault }) {
      if (!add) {
        this.$refs.elementRef.clear()
      }
      if (!del && !setDefault) this.selectedPaymentMethod = null
    }
  },

  data() {
    return {
      loading: false,
      dialogs: {
        add: false,
        delete: false,
        setDefault: false
      },
      selectedPaymentMethod: null,
      pk: process.env.VUE_APP_STRIPE_PK,
      headers: [
        {
          text: "Brand",
          sortable: false,
          value: "brand"
        },
        {
          text: "Last 4 Digits",
          sortable: false,
          value: "last4"
        },
        {
          text: "Expiry",
          sortable: false,
          value: "expiry"
        },
        {
          text: "",
          align: "end",
          sortable: false,
          value: "action"
        }
      ]
    }
  },

  computed: {
    ...mapGetters(["currentTenant", "paymentMethods"])
  },

  methods: {
    ...mapActions(["triggerReload"]),
    capitalise,

    addCard() {
      this.loading = true
      this.$refs.elementRef.submit()
    },

    async tokenCreated(cardToken) {
      try {
        await client.gateway.billing.addPaymentMethod(cardToken.id)
      } catch {
        this.loading = false
      }
      this.triggerReload("paymentMethods")
      this.dialogs.add = false
    },

    async setDefaultPayment() {
      this.loading = true
      await client.gateway.billing.setDefaultPaymentMethod(
        this.selectedPaymentMethod
      )
      this.triggerReload("paymentMethods")
      this.dialogs.setDefault = false
    },

    handleConfirm(paymentMethod, dialog) {
      this.selectedPaymentMethod = paymentMethod
      this.dialogs[dialog] = true
    },

    async deletePayment() {
      this.loading = true
      await client.gateway.billing.removePaymentMethod(
        this.selectedPaymentMethod
      )
      this.triggerReload("paymentMethods")
      this.dialogs.delete = false
    }
  },

  async mounted() {
    this.loading = true
    if (this.paymentMethods) this.loading = false
    else this.triggerReload("paymentMethods")
  }
}
</script>
