<template>
  <v-card flat>
    <v-card-title class="title-font">
      <v-icon :color="file ? 'success' : 'error'" class="mr-2">
        {{ file ? "mdi-check-circle" : "mdi-close-circle" }}
      </v-icon>
      {{ categoryTitle }}
      <small v-if="categorySubtitle" class="ml-3"
        >{{ categorySubtitle }}
      </small>
    </v-card-title>
    <v-card-subtitle class="error--text ml-3" v-if="error">
      Required
    </v-card-subtitle>
    <v-card-text>
      <v-row>
        <v-col cols="5">
          <ExistingFileUploader v-model="file" :category="category" />
        </v-col>
        <v-col cols="5">
          <FileUploader
            v-model="file"
            text="Upload"
            :fileExtension="permittedExtensions"
            :category="category"
          />
        </v-col>
        <v-col cols="2">
          <v-card
            outlined
            class="rounded-10 overflow-hidden grey-border"
            height="86"
          >
            <ModelViewer
              v-if="fileType === 'scan' && file"
              :filename="file.file_name"
              :url="file.url"
              :height="86"
              :width="110"
              class="pa-0 ma-0"
            />
            <v-img
              v-if="fileType === 'photo' && file"
              contain
              height="100%"
              :src="file.url"
            />
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import clientOptions from "@/plugins/axios/client_options"
import ExistingFileUploader from "./FileUploadAndPreview/ExistingFileUploader.vue"
import FileUploader from "./FileUploadAndPreview/FileUploader.vue"
import ModelViewer from "@/components/shared/ModelViewer.vue"

export default {
  name: "FileUploadAndPreview",

  props: ["value", "categoryTitle", "category", "categorySubtitle", "fileType"],

  inject: ["form"],

  components: { ExistingFileUploader, FileUploader, ModelViewer },

  data() {
    return {
      error: null
    }
  },

  computed: {
    file: {
      get() {
        return this.value
      },

      async set(value) {
        if (value) {
          this.error = null
          const oftResponse = await this.oftFetchDownloadUrl(value)
          value.url = oftResponse.data.oneTimeDownloadLink
        }
        this.$emit("input", value)
      }
    },

    permittedExtensions() {
      if (this.fileType === "scan") return ["stl", "ply"]
      if (this.fileType === "photo") return ["png", "jpg", "jpeg"]
      return []
    }
  },

  methods: {
    validate() {
      if (!this.file) {
        this.error = "Must be selected"
      }
      return !!this.file
    },

    async oftFetchDownloadUrl(file) {
      try {
        const response = await this.$axios.post(
          `${clientOptions.oftURL}/${file.oft_file_uid}/download`
        )
        return response
      } catch (error) {
        console.error(error)
      }
    }
  },

  created() {
    this.form.register(this)
  }
}
</script>
