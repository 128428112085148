<template>
  <v-card
    class="rounded-20 pb-2 px-2"
    @click.stop
    :ripple="false"
    style="cursor: default"
  >
    <v-card-title class="title-font font-weight-bold mb-1">
      UPGRADE SUBSCRIPTION
    </v-card-title>
    <v-card-text>
      <p class="black--text ma-0 mr-5 title-font">
        You've exhausted your
        {{ capitalise(item.product_code) }} credits! <br />Consider upgrading
        your subscription.
      </p>
      <v-btn
        class="btn-primary mt-8"
        width="100%"
        @click="
          $router.push({
            name: 'profilePage',
            params: { change: true }
          })
        "
      >
        Upgrade
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<style scoped>
.v-card--link:before {
  background: none;
}
</style>

<script>
import { capitalise } from "@/utils"
export default {
  props: ["item"],
  methods: {
    capitalise
  }
}
</script>
