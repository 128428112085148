<template>
  <div>
    <div class="text-center mt-5 title-font">
      <span class="pricing-card-price">
        ${{ pricing?.subscription_price.toLocaleString() }}
      </span>
      <span v-if="pricing.renewal_frequency === 'monthly'">/month </span>
      <span v-if="pricing.renewal_frequency === 'yearly'">/year </span>
    </div>
    <div class="ma-5">
      <v-btn
        v-if="!returnButton"
        class="btn-primary rounded-10"
        block
        large
        text
        @click="$emit('selection')"
      >
        Get started
      </v-btn>
      <v-btn
        v-if="returnButton"
        class="btn-primary rounded-10"
        block
        large
        text
        :to="{
          name: 'pricingPage'
        }"
      >
        Modify
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: ["pricing", "returnButton"]
}
</script>
