var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.currentQuotas)?_c('v-card-text',{staticClass:"text-center pt-9"},[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1):_vm._e(),_vm._l((_vm.quotasForOverview),function(item){return _c('v-card-text',{key:item.quota.title,staticClass:"pb-2"},[_c('v-row',{staticClass:"my-1 px-3",attrs:{"align":"center"}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(_vm._s(item.quota.icon))]),_c('span',{staticClass:"black--text font-weight-bold ml-2 title-font"},[_vm._v(" "+_vm._s(item.quota.title)+" ")])],1),_c('v-row',{staticClass:"my-1 pl-14 pr-3 flex-column"},[_c('span',{staticClass:"mb-1"},[_vm._v(" Credits Remaining - ( "+_vm._s(item.credits)+" of "+_vm._s(item.starting)+" ) ")]),_c('div',{staticClass:"flex-grow-1",style:('display: grid; grid-template-columns: ' +
          `${item.incoming}fr ` +
          `${item.starting_rollover}fr;`)},[_c('v-progress-linear',{class:item.starting_rollover ? 'rounded-lg rounded-r-0' : 'rounded-lg',attrs:{"height":"20","value":Math.floor(
              (Math.min(item.incoming, item.credits) / item.incoming) * 100
            )}}),_c('v-progress-linear',{staticClass:"rounded-lg rounded-l-0",attrs:{"height":"20","color":"success","value":Math.floor(
              (Math.max(
                0,
                item.starting_rollover - (item.starting - item.credits)
              ) /
                item.starting_rollover) *
                100
            )}})],1)])],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }