<template>
  <div>
    <v-card-text class="pb-0">
      <v-form ref="businessInfoForm" lazy-validation>
        <v-text-field
          autofocus
          v-model="form.name"
          required
          :rules="rules"
          class="mb-n1"
          outlined
          dense
          label="Business Name *"
        />
        <PhoneInput
          v-model="form.phone_number"
          label="Phone Number *"
          :country="country"
        />
        <v-text-field
          v-model="form.email_address"
          required
          :rules="[...rules, ...emailRule]"
          outlined
          dense
          type="email"
          label="Email *"
        />
        <BillingAddress v-model="form" />
        <ShippingAddress v-model="form" v-if="!sameShippingAddress" />
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn class="btn-primary rounded-10 ma-2" @click="next()" text tile>
        Continue
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import BillingAddress from "./BusinessInformation/BillingAddress.vue"
import ShippingAddress from "./BusinessInformation/ShippingAddress.vue"
import PhoneInput from "@/components/shared/PhoneInput.vue"

const shippingDetails = [
  "shipping_address_1",
  "shipping_address_2",
  "shipping_suburb",
  "shipping_country",
  "shipping_state",
  "shipping_postal_code"
]

export default {
  name: "BusinessInformation",

  props: ["value"],

  components: {
    BillingAddress,
    ShippingAddress,
    PhoneInput
  },

  data() {
    return {
      rules: [(v) => !!v || "Required"],
      emailRule: [
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/.test(v) ||
          "E-mail must be valid"
      ]
    }
  },

  watch: {
    sameShippingAddress(value) {
      if (value) {
        const copy = { ...this.value }
        shippingDetails.forEach((key) => {
          delete copy[key]
        })
        this.$emit("input", copy)
      }
      if (!value) {
        const copy = { ...this.value }
        shippingDetails.forEach((key) => {
          const billingKey = key.replace("shipping", "billing")
          copy[key] = copy[billingKey]
        })
        this.$emit("input", copy)
      }
    }
  },

  computed: {
    ...mapGetters(["country"]),

    form() {
      return this.value
    },

    sameShippingAddress() {
      return this.value.sameShippingAddress
    }
  },

  methods: {
    next() {
      if (this.$refs.businessInfoForm.validate()) {
        this.$emit("next")
      }
    }
  }
}
</script>
