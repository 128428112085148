<template>
  <v-card flat>
    <v-card-title class="pl-0"> Case Type </v-card-title>

    <v-row class="mx-3">
      <v-col>
        <v-switch v-model="immediate" label="Surgical (Immediate)" />
      </v-col>
      <v-col>
        <v-switch
          :value="immediate === undefined ? false : !immediate"
          @click="immediate = immediate === undefined ? false : !immediate"
          label="Restorative (Final)"
        />
      </v-col>
    </v-row>
    <v-fade-transition>
      <span class="error--text ml-6" v-if="error">
        {{ this.error }}
      </span>
    </v-fade-transition>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "NewOrderCaseType",

  props: ["value"],

  inject: ["form"],

  data() {
    return {
      error: null
    }
  },

  computed: {
    ...mapGetters(["settings"]),

    immediate: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      }
    }
  },

  methods: {
    validate() {
      if (this.immediate === undefined) {
        this.error = "Select a Case Type"
      }
      return this.immediate !== null
    }
  },

  created() {
    this.form.register(this)
  }
}
</script>
