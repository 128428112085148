<template>
  <v-container fluid fill-height class="d-flex align-center justify-center">
    <SelectTenant @onContinue="onContinue" :tenants="tenants" />
  </v-container>
</template>

<script>
import SelectTenant from "./SelectTenantPage/SelectTenant"
import client from "@/lib/ApiClient"

import { mapActions, mapGetters } from "vuex"

export default {
  name: "SelectTenantPage",

  components: { SelectTenant },

  computed: {
    ...mapGetters(["tenants"])
  },

  methods: {
    ...mapActions(["setCurrentTenant", "setSubscription", "triggerReload"]),

    async onContinue(selectedTenantUID) {
      const selectedTenant = this.tenants.find(
        (item) => item.uid === selectedTenantUID
      )
      this.setCurrentTenant(selectedTenant)
      const subscriptionResponse =
        await client.gateway.billing.subscriptionInformation()
      this.setSubscription(subscriptionResponse)
      this.triggerReload("all")
      this.$router.push({ name: "inboxPage" })
    }
  }
}
</script>
