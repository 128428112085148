<template>
  <v-dialog
    max-width="max-content"
    v-model="dialog"
    content-class="rounded-20"
    :persistent="loading"
  >
    <v-card>
      <v-card-title class="title-font font-weight-bold">
        {{ suggesting ? "Upgrade" : "Change" }} your subscription?
        <v-progress-circular
          indeterminate
          size="32"
          class="ml-5"
          v-if="loading"
        />
        <v-spacer />
        <v-btn icon @click="dialog = false" :disabled="loading">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-subtitle v-if="subscriptionSchedule" class="pt-2">
        Changing your subscription will cancel your currently scheduled
        {{
          isUpgrade(
            plans.find((p) => subscriptionSchedule === p.code)
          ).toLowerCase()
        }}.
      </v-card-subtitle>
      <v-card-text>
        <v-row> </v-row>
        <v-row justify="center">
          <v-col v-for="(pricing, index) in filteredPlans" :key="index">
            <PricingCard
              :pricing="pricing"
              :change="isUpgrade(pricing)"
              :loading="loading"
              @selected="handleSelection"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters, mapActions } from "vuex"
import client from "@/lib/ApiClient"
import PricingCard from "@/components/PricingPage/PricingCard.vue"

export default {
  name: "ChangeDialog",

  props: ["value", "suggesting"],

  components: {
    PricingCard
  },

  data() {
    return {
      loading: false
    }
  },

  computed: {
    ...mapGetters(["plans", "subscription", "subscriptionSchedule"]),
    dialog: {
      get() {
        return this.value
      },

      set(payload) {
        return this.$emit("input", payload)
      }
    },
    filteredPlans() {
      // const result = this.plans.filter(
      //   (p) => p.code !== this.subscription.plan_code
      // )
      if (this.suggesting)
        return this.plans.filter((p) => this.isUpgrade(p) !== "DOWNGRADE")
      return this.plans
    }
  },

  methods: {
    ...mapActions([
      "setSubscription",
      "setCurrentQuotas",
      "setSubscriptionSchedule",
      "triggerReload"
    ]),

    handleSelection(plan_code, schedule = true) {
      if (plan_code === this.subscriptionSchedule) this.releaseSchedule()
      else this.changeSubscription(plan_code, schedule)
    },

    async poll() {
      const subscriptionResponse =
        await client.gateway.billing.subscriptionInformation()
      if (subscriptionResponse.activated) {
        // Polling for immediate upgrade, should it ever have schedule?
        if (this.subscription.has_schedule)
          this.setSubscriptionSchedule(
            await client.gateway.billing.subscriptionSchedule()
          )
        this.setSubscription(subscriptionResponse)
        this.triggerReload("quotas")
        this.loading = false
        this.dialog = false
      } else return setTimeout(this.poll, 500)
    },

    async changeSubscription(plan_code, schedule) {
      this.loading = true
      await client.gateway.billing.changeSubscription({
        plan_code,
        schedule
      })
      if (schedule) {
        this.setSubscriptionSchedule(
          await client.gateway.billing.subscriptionSchedule()
        )
        this.loading = false
        this.dialog = false
      } else this.poll()
    },

    async releaseSchedule() {
      this.loading = true
      await client.gateway.billing.releaseSchedule()
      this.setSubscriptionSchedule(null)
      this.loading = false
      this.dialog = false
    },

    isUpgrade(pricingOrPlan) {
      const currentPlan = this.plans.find(
        (p) => p.code === this.subscription.plan_code
      )
      return pricingOrPlan.subscription_price > currentPlan.subscription_price
        ? "UPGRADE"
        : pricingOrPlan.subscription_price < currentPlan.subscription_price
        ? "DOWNGRADE"
        : "CURRENT"
    }
  }
}
</script>
