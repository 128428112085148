<template>
  <v-card flat>
    <v-card-title class="px-0">Upload Files</v-card-title>
    <v-card-text class="px-0">
      <v-row>
        <v-col cols="4">
          <NewOrderFileUploader
            v-model="scanFileUploads"
            text="Scan File"
            accept-formats="stl, ply"
          />
          <v-row no-gutters>
            <v-col
              v-for="(file, index) in scanFileUploads"
              :key="index"
              class="my-1 mx-auto"
            >
              <v-chip
                small
                close
                class="rounded-10"
                @click:close="removeFile(scanFileUploads, index)"
              >
                <v-icon small>mdi-paperclip</v-icon>
                <span class="text-truncate">
                  {{ file.name }}
                </span>
              </v-chip>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="4">
          <NewOrderFileUploader
            v-model="photoFileUploads"
            text="Photo File"
            accept-formats="png, jpg, jpeg, svg"
          />
          <v-row no-gutters>
            <v-col
              v-for="(file, index) in photoFileUploads"
              :key="index"
              class="my-1 mx-auto"
            >
              <v-chip
                small
                close
                class="rounded-10"
                @click:close="removeFile(photoFileUploads, index)"
              >
                <v-icon small>mdi-paperclip</v-icon>
                <span class="text-truncate">
                  {{ file.name }}
                </span>
              </v-chip>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="4">
          <NewOrderFileUploader v-model="otherFileUploads" text="Other File" />
          <v-row no-gutters>
            <v-col
              v-for="(file, index) in otherFileUploads"
              :key="index"
              class="my-1 mx-auto"
            >
              <v-chip
                small
                close
                class="rounded-10"
                @click:close="removeFile(otherFileUploads, index)"
              >
                <v-icon small>mdi-paperclip</v-icon>
                <span class="text-truncate">
                  {{ file.name }}
                </span>
              </v-chip>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import NewOrderFileUploader from "./AdditionalFileUploader/NewOrderFileUploader.vue"

export default {
  components: {
    NewOrderFileUploader
  },

  props: ["scanFiles", "photoFiles", "otherFiles"],

  computed: {
    scanFileUploads: {
      get() {
        return this.scanFiles
      },
      set(val) {
        this.$emit("updateFiles", "scanFileUploads", val)
      }
    },
    photoFileUploads: {
      get() {
        return this.photoFiles
      },
      set(val) {
        this.$emit("updateFiles", "photoFileUploads", val)
      }
    },
    otherFileUploads: {
      get() {
        return this.otherFiles
      },
      set(val) {
        this.$emit("updateFiles", "otherFileUploads", val)
      }
    }
  },

  methods: {
    removeFile(files, index) {
      files.splice(index, 1)
    }
  }
}
</script>
