<template>
  <div>
    <v-card flat class="my-4 rounded-20" v-if="fullmode">
      <v-card-title class="title-font"> CASE CHAT </v-card-title>
    </v-card>

    <v-card flat class="mr-4 rounded-20">
      <v-card-title class="title-font" v-if="!fullmode">
        CASE CHAT
        <v-spacer />
        <v-tooltip top class="rounded-10">
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="$emit('fullmode')" icon v-bind="attrs" v-on="on">
              <v-icon> mdi-arrow-expand-all </v-icon>
            </v-btn>
          </template>
          <span>Expand Chat</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <MessageComponent
          :messages="messages"
          :approvals="approvals"
          :loading-message="loadingMessage"
          :sending-message="sendingMessage"
          @compose="sendMessage"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import client from "@/lib/ApiClient"
import clientOptions from "@/plugins/axios/client_options"
import MessageComponent from "./CaseChat/MessageComponent.vue"

export default {
  components: { MessageComponent },

  props: ["fullmode"],

  data() {
    return {
      loadingMessage: false,
      sendingMessage: false
      // approvals: []
    }
  },

  watch: {
    orderMessages(val) {
      if (val && this.orderApprovals) {
        this.loadingMessage = false
        this.sendingMessage = false
      }
    },

    orderApprovals(val) {
      if (val && this.orderMessages) {
        this.loadingMessage = false
      }
    }
  },

  computed: {
    ...mapGetters([
      "selectedOrder",
      "orderMessages",
      "orderApprovals",
      "country"
    ]),

    messages() {
      if (this.orderMessages === null) return []
      return this.orderMessages
    },

    approvals() {
      if (this.orderApprovals === null) return []
      return this.orderApprovals
    }
  },

  methods: {
    ...mapActions(["triggerReload"]),

    async fetchApprovals() {
      if (this.orderApprovals === null) this.loadingMessage = true
      this.triggerReload("approvals")
    },

    async fetchMessages() {
      if (this.orderMessages === null) this.loadingMessage = true
      this.triggerReload("messages")
    },

    async sendMessage(message, attachments) {
      this.sendingMessage = true
      const promises = []

      if (attachments.length) {
        const oftResponse = await this.oftAddFiles(attachments)

        const payload = []

        for (const [index, f] of oftResponse.data.entries()) {
          promises.push(
            this.uploadToGoogleStorage(attachments[index], f.oneTimeUploadLink)
          )

          payload.push({
            file_name: f.fileName,
            oft_file_uid: f.uid
          })
        }

        message.attachments = payload
      }

      promises.push(
        client.instance.messages.sendMessage(this.selectedOrder.uid, message)
      )

      await Promise.all(promises)
      this.fetchMessages()
    },

    async oftAddFiles(attachments) {
      const payload = attachments.map((file) => {
        return {
          fileName: file.name,
          fileReferences: [
            {
              entityType: "NCLABS_ORDER",
              entityIdentification: this.selectedOrder.uid
            }
          ]
        }
      })
      try {
        const response = await this.$axios.post(
          `${clientOptions.oftURL}/upload/${this.country}`,
          payload
        )
        return response
      } catch (error) {
        console.error(error)
      }
    },

    async uploadToGoogleStorage(file, uploadLink) {
      try {
        const response = await fetch(uploadLink, {
          method: "PUT",
          headers: {
            "Content-Type": "application/octet-stream"
          },
          body: file
        })
        return response
      } catch (error) {
        console.error(error)
      }
    }
  },

  mounted() {
    this.fetchMessages()
    this.fetchApprovals()
  }
}
</script>
