<template>
  <v-card
    class="rounded-10 mb-2 overflow-y-auto"
    outlined
    min-height="100"
    max-height="420"
    ref="messagesCard"
  >
    <v-card-text>
      <v-skeleton-loader v-if="loadingMessage" type="text@6" />
      <div v-if="!timeline.length && !loadingMessage" class="text-center my-5">
        <v-icon>mdi-information</v-icon>
        No messages found
      </div>
      <span v-for="(item, index) in timeline" :key="index">
        <MessageItem
          v-if="item.__type === 'message'"
          :message="item"
          class="py-1"
        />
        <ApprovalBubble
          v-if="item.__type === 'approval'"
          :approval="item"
          class="py-1"
        />
      </span>
    </v-card-text>
  </v-card>
</template>

<script>
import MessageItem from "./MessageList/MessageItem.vue"
import ApprovalBubble from "./MessageList/ApprovalBubble.vue"

export default {
  props: ["messages", "approvals", "loadingMessage"],

  components: { MessageItem, ApprovalBubble },

  watch: {
    messages() {
      this.scrollToBottom()
    }
  },

  computed: {
    timeline() {
      let items = []
      items = items.concat(
        this.messages.map((message) => {
          return {
            ...message,
            __type: "message"
          }
        })
      )
      items = items.concat(
        this.approvals.map((approval) => {
          return {
            ...approval,
            __type: "approval"
          }
        })
      )
      return items.sort(
        (a, b) => new Date(a.created_at) - new Date(b.created_at)
      )
    }
  },

  methods: {
    scrollToBottom() {
      this.$nextTick(() => {
        const container = this.$refs.messagesCard.$el
        container.scrollTop = container.scrollHeight
      })
    }
  },

  mounted() {
    this.scrollToBottom()
  }
}
</script>
