<template>
  <v-card flat class="ml-4 rounded-20">
    <v-card-title class="title-font"> SCAN GAUGE KIT: </v-card-title>
    <v-card-text>
      <v-row>
        <v-col v-for="(gaugeCuff, group) in gauges" :key="group" cols="6">
          <v-card class="rounded-10" flat outlined>
            <v-row>
              <v-col v-for="(gauge, index) in gaugeCuff" :key="index" cols="4">
                <div class="pa-2 d-flex justify-center">
                  <v-img :src="gauge.src" contain max-width="37.5">
                    <div
                      v-ripple
                      :class="[gaugeClass(gauge), 'fill-height']"
                      @click="handleGaugeSelection(gauge)"
                    ></div>
                  </v-img>
                  <v-hover
                    v-slot="{ hover }"
                    v-if="libraryAssociation.includes(gauge.scanBodyCode)"
                  >
                    <v-badge
                      bordered
                      style="cursor: pointer"
                      :color="hover ? 'error' : 'success'"
                      :icon="hover ? 'mdi-close' : null"
                      :content="
                        hover
                          ? null
                          : libraryAssociation.findIndex(
                              (c) => c === gauge.scanBodyCode
                            ) + 1
                      "
                      overlap
                      @click.native="$emit('delete', gauge.scanBodyCode)"
                    />
                  </v-hover>
                </div>
              </v-col>
            </v-row>
            <v-card-actions>
              <v-spacer />
              <v-card-subtitle class="py-0 title-font">
                {{ `${group === 0 ? "3mm Cuff Height" : "5mm Cuff Height"}` }}
              </v-card-subtitle>
              <v-spacer />
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <v-overlay
      :absolute="true"
      :value="libraryAssociation.every((l) => l === null) && !active"
      class="overlay__content"
    >
      <v-card
        class="transparent elevation-0 d-flex align-start justify-center"
        style="height: 100%; width: 100%"
        @click="$emit('start')"
        :disabled="loading"
      >
        <v-fade-transition>
          <span class="title-font pa-5" v-if="!loading">
            Click to Get Started
          </span>
        </v-fade-transition>
      </v-card>
    </v-overlay>
  </v-card>
</template>

<style scoped>
.overlay__content >>> .v-overlay__content {
  height: 100% !important;
  width: 100% !important;
}
</style>

<script>
export default {
  name: "GaugeSelection",

  props: ["value", "gauges", "libraryAssociation", "active", "loading"],

  computed: {
    selectedGauge: {
      get() {
        return this.value
      },

      set(value) {
        this.$emit("input", value)
      }
    }
  },

  methods: {
    handleGaugeSelection(gauge) {
      if (this.libraryAssociation.includes(gauge.scanBodyCode)) return
      if (!this.selectedGauge) {
        this.selectedGauge = gauge
      } else {
        if (gauge.scanBodyCode === this.selectedGauge.scanBodyCode) {
          this.selectedGauge = null
        } else {
          this.selectedGauge = gauge
        }
      }
    },

    gaugeClass(gauge) {
      if (this.selectedGauge) {
        if (gauge.scanBodyCode === this.selectedGauge.scanBodyCode) {
          return "selected-gauge hover-gauge"
        } else if (this.libraryAssociation.includes(gauge.scanBodyCode)) {
          return "associated-gauge"
        }
      } else {
        if (this.libraryAssociation.includes(gauge.scanBodyCode)) {
          return "associated-gauge"
        }
      }
      return "hover-gauge"
    }
  }
}
</script>
