<template>
  <v-dialog v-model="dialog" max-width="500px" content-class="rounded-20">
    <v-card flat>
      <v-card-title class="off">
        <span class="text-h5">Set a category for the file</span>
      </v-card-title>
      <v-card-text class="py-5">
        <v-list
          dense
          class="transparent pa-0"
          v-for="(category, index) in categories"
          :key="index"
        >
          <v-list-item
            class="mb-2 custom-grey"
            :input-value="setCategory === category.value"
            @click="setCategory = category.value"
          >
            <v-list-item-content class="title-font">
              <v-list-item-title class="text-wrap">
                {{ category.title }}
              </v-list-item-title>
              <v-list-item-subtitle class="text-wrap">
                {{ category.subtitle }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions class="off">
        <v-spacer />
        <v-btn
          text
          class="btn-error"
          :disabled="loading"
          @click="dialog = false"
        >
          Close
        </v-btn>
        <v-btn text class="btn-primary" :loading="loading" @click="save()">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ScansAndFiles",

  props: ["value", "loading", "files", "categories"],

  data() {
    return {
      setCategory: ""
    }
  },

  computed: {
    dialog: {
      get() {
        return this.value
      },

      set(value) {
        this.setCategory = ""
        this.$emit("input", value)
      }
    }
  },

  methods: {
    isCategorized(category) {
      return (
        this.files.filter((file) => file.file_category === category).length > 0
      )
    },

    save() {
      this.$emit("setCategory", this.setCategory)
      this.dialog = false
    }
  }
}
</script>
