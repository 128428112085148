<template>
  <v-app fill-height>
    <NavigationDrawer v-if="hasTenant && activeSubscription" />
    <SystemBar />
    <AppBar />
    <v-main class="gradient-bg">
      <ToolBar v-if="hasTenant && activeSubscription" />
      <v-container fluid v-if="loading">
        <v-row>
          <v-col class="text-center">
            <v-progress-circular
              indeterminate
              v-if="loading"
              size="128"
              class="my-10"
            />
          </v-col>
        </v-row>
      </v-container>
      <v-container fluid v-if="!loading">
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import { login } from "./lib/authentication"
import AppBar from "@/components/App/AppBar"
import SystemBar from "@/components/App/SystemBar"
import NavigationDrawer from "@/components/App/NavigationDrawer"
import ToolBar from "@/components/App/ToolBar"
import client from "@/lib/ApiClient"

export default {
  name: "App",

  components: {
    AppBar,
    SystemBar,
    NavigationDrawer,
    ToolBar
  },

  watch: {
    applicationInitialized(value) {
      if (
        value === true &&
        this.entryRoute !== null &&
        this.entryRoute !== this.$router.currentRoute
      ) {
        if (
          (this.entryRoute.meta.requiresLogin && !this.loggedIn) ||
          (this.entryRoute.meta.requiresRegistration && !this.registered) ||
          (this.entryRoute.meta.requiresTenant && !this.hasTenant) ||
          (this.entryRoute.meta.requiresSubscription && !this.subscription)
        )
          return
        this.$router.push(this.entryRoute)
      }
    },

    reloadQueue(value) {
      if (!value || !value.length) return
      this.reloadData()
    }
  },

  computed: {
    ...mapGetters([
      "applicationInitialized",
      "entryRoute",
      "loggedIn",
      "registered",
      "country",
      "loading",
      "tenants",
      // "features",
      "hasTenant",
      "currentTenant",
      "subscription",
      "activeSubscription",
      "selectedOrder",
      "reloadQueue"
    ])
  },

  methods: {
    ...mapActions([
      "triggerReload",
      "setApplicationInitialized",
      "setIntrospection",
      "setPlans",
      "setInstances",
      "setCountry",
      "setToken",
      "startLoading",
      "stopLoading",
      "setMe",
      "setTenants",
      "setCurrentTenant",
      "setSubscription",
      "setSubscriptionSchedule",
      "reloadData"
    ]),

    ...mapActions("nxc", ["setRoles"]),

    async detectCountry() {
      return new Promise((resolve, reject) => {
        this.$axiosAuth
          .get("https://ipapi.co/json")
          .then((response) => {
            this.setCountry(response.data.country)
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  },

  async beforeMount() {
    this.startLoading()
    await this.detectCountry()

    try {
      let authMeResponse = null
      try {
        authMeResponse = await this.$axiosAuth.get("/me")
      } catch {
        authMeResponse = null
        return login()
      }
      if (authMeResponse) {
        this.setToken(authMeResponse.data.access_token)
        this.$axios.defaults.headers.Authorization = `Bearer ${authMeResponse.data.access_token}`
      }

      try {
        this.setIntrospection(await client.gateway.users.fetchID())
      } catch (error) {
        console.error(error)
      }

      const plansResponse = await client.gateway.plans.fetchAllPlans(
        this.country
      )
      this.setPlans(plansResponse)

      const instancesResponse = await client.gateway.instances.fetchInstances()
      this.setInstances(instancesResponse)

      const meResponse = await client.gateway.users.fetchMe()
      if (meResponse) {
        this.setMe(meResponse)
        const tenantsResponse = await client.gateway.tenants.fetchUserTenants()
        this.setTenants(tenantsResponse)

        if (this.tenants.length === 1) {
          this.setCurrentTenant(this.tenants[0])
          const subscriptionResponse =
            await client.gateway.billing.subscriptionInformation()
          this.setSubscription(subscriptionResponse)
          if (subscriptionResponse.has_schedule) {
            const scheduleResponse =
              await client.gateway.billing.subscriptionSchedule()
            this.setSubscriptionSchedule(scheduleResponse)
          }
        } else if (
          this.tenants.length > 1 &&
          this.$router.currentRoute.name !== "selectTenantPage"
        ) {
          this.$router.push({
            name: "selectTenantPage"
          })
        }
      }

      let callbackRoute = sessionStorage.getItem("callbackRoute")
      if (callbackRoute) {
        sessionStorage.removeItem("callbackRoute")
        callbackRoute = JSON.parse(callbackRoute)
        this.$router.push(callbackRoute)
      }

      setInterval(() => {
        this.triggerReload("all")
      }, 60_000)
      this.triggerReload("all")

      this.setApplicationInitialized(true)
      this.stopLoading()
    } catch {
      this.setApplicationInitialized(true)
      this.stopLoading()
    }
  }
}
</script>
