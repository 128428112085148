<template>
  <v-card-text class="py-1">
    <v-row no-gutters class="mb-3">
      <v-col
        v-for="(_, index) in selectedGaugeLibraries"
        :key="index"
        align="center"
        class="my-1"
      >
        <v-btn icon elevation="0" @click="$emit('select', index)">
          <v-avatar
            size="32"
            :color="
              index === selectedScanGaugeIndex
                ? 'primary'
                : selectedGaugeLibraries[index]
                ? 'success'
                : ''
            "
          >
            <span
              v-if="
                index === selectedScanGaugeIndex ||
                selectedGaugeLibraries[index]
              "
              class="white--text"
            >
              {{ index + 1 }}
            </span>
            <span
              v-if="
                index !== selectedScanGaugeIndex &&
                !selectedGaugeLibraries[index]
              "
            >
              {{ index + 1 }}
            </span>
          </v-avatar>
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col align="center">
        <v-btn
          elevation="0"
          :class="[
            { 'rounded-10': true },
            { 'mb-2': true },
            { 'btn-primary': readyToComplete }
          ]"
          @click="$emit('confirm')"
          :disabled="!readyToComplete"
        >
          <v-icon>mdi-check</v-icon>
          Confirm Association
        </v-btn>
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
export default {
  name: "ScanSelectConfirm",

  props: ["selectedGaugeLibraries", "selectedScanGaugeIndex", "loading"],

  computed: {
    readyToComplete() {
      if (this.loading) return false
      return this.selectedGaugeLibraries.every((x) => x)
    }
  }
}
</script>
