<template>
  <v-dialog
    v-model="showModal"
    content-class="rounded-20"
    max-width="600"
    overlay-opacity="0.8"
  >
    <v-card width="600" v-if="idConflict">
      <v-card-title class="off mb-5">
        <h2 class="title-h2">Already Registered</h2>
      </v-card-title>
      <v-card-text>
        <p>
          The {{ providedIDString.type }} you're attempting to register with is
          already associated with another account:
        </p>
        <p>
          <strong>
            {{ providedIDString.idString }}
          </strong>
        </p>
        <p>
          Please either login with this {{ providedIDString.type }}, or use a
          different {{ providedIDString.type }}.
        </p>
        <v-row class="my-5">
          <v-col cols="12" class="text-center">
            <v-btn class="btn-primary rounded-10" @click="logout()">
              Login with this {{ toTitleCase(providedIDString.type) }}
            </v-btn>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-btn class="btn-primary rounded-10" @click="idConflict = false">
              Use a Different {{ toTitleCase(providedIDString.type) }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card width="600" v-if="!idConflict">
      <v-card-title class="off mb-5">
        <h2 class="title-h2">Let's Get Started...</h2>
      </v-card-title>
      <v-card-text>
        <BasicInformation
          ref="basicInformation"
          v-model="signUpForm"
          :id-string="idString"
        />
      </v-card-text>
      <v-card-actions class="off">
        <v-spacer></v-spacer>
        <v-btn class="btn-primary rounded-10" @click="onSignUp()">
          Continue
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { toTitleCase } from "@/utils"
import { logout } from "@/lib/authentication"
import { mapActions, mapGetters } from "vuex"
import BasicInformation from "./SignUp/BasicInformation"
import client from "@/lib/ApiClient"

export default {
  name: "SignUp",

  props: ["value", "selectedPlanCode"],

  components: {
    BasicInformation
  },

  data() {
    return {
      signUpForm: {
        firstName: null,
        lastName: null,
        email: null,
        phone: null
      },
      idString: null,
      idConflict: false
    }
  },

  watch: {
    value(value) {
      if (!value) {
        return
      }
      this.signUpForm.email = this.introspection.email
      this.signUpForm.phone = this.introspection.phone
      this.introspection.email
        ? (this.idString = "email")
        : (this.idString = "phone")
    }
  },

  computed: {
    ...mapGetters(["introspection"]),

    showModal: {
      get() {
        return this.value
      },

      set(value) {
        this.$emit("input", value)
      }
    },

    providedIDString() {
      if (!this.idString) {
        return
      }
      if (this.idString.startsWith("+")) {
        return { idString: this.signUpForm.email, type: "email address" }
      }
      return { idString: this.signUpForm.phone, type: "phone number" }
    }
  },

  methods: {
    ...mapActions(["setMe"]),

    toTitleCase(string) {
      return toTitleCase(string)
    },

    logout() {
      return logout()
    },

    async onSignUp() {
      if (!this.$refs.basicInformation.validateForm()) {
        return
      }
      const form = {
        first_name: this.signUpForm.firstName,
        last_name: this.signUpForm.lastName,
        email_address: this.signUpForm.email,
        phone_number: this.signUpForm.phone
      }

      try {
        await client.gateway.users.signUp(form)
      } catch (error) {
        if (error.response.status === 422) {
          this.idConflict = true
        }
        return
      }

      try {
        const meResponse = await client.gateway.users.fetchMe()
        if (meResponse) {
          this.setMe(meResponse)
        }
      } catch (error) {
        return
      }

      this.$router.push({
        name: "registrationPage",
        params: { planCode: this.selectedPlanCode }
      })
    }
  }
}
</script>
