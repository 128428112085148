<template>
  <v-card flat>
    <v-card-text class="pb-0 px-0">
      <v-row class="mx-0 pb-1">
        <v-text-field
          v-model="question.name"
          dense
          outlined
          label="Name"
          :value="question.name"
          :error-messages="
            !question.name && validate ? 'Cannot be Empty' : null
          "
        />
        <v-switch
          v-model="question.mandatory"
          label="Mandatory"
          class="px-5 mt-1"
          inset
          hide-details
        />
      </v-row>
      <v-row class="mx-0 pb-1">
        <v-text-field
          v-model="question.description"
          dense
          outlined
          label="Description"
          :value="question.description"
        />
      </v-row>
      <v-row class="mx-0">
        <v-select
          v-model="question.widget"
          outlined
          dense
          label="Widget"
          :menu-props="{ rounded: '10' }"
          :items="items"
          :error-messages="
            !question.widget && validate ? 'Cannot be Empty' : null
          "
        />
      </v-row>
      <ResponseList v-model="question" :validate="validate" />
    </v-card-text>
  </v-card>
</template>

<script>
import ResponseList from "./QuestionForm/ResponseList.vue"

export default {
  props: ["value", "validate"],

  components: { ResponseList },

  data() {
    return {
      items: [
        { text: "Text Field", value: "text_field" },
        { text: "Text Area", value: "text_area" },
        { text: "Drop Down Picker", value: "drop_down_picker" },
        { text: "Tooth Picker", value: "tooth_picker" },
        { text: "Datetime Picker", value: "datetime_picker" }
      ]
    }
  },

  computed: {
    question: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      }
    }
  }
}
</script>
