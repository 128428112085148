<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
    content-class="rounded-20"
    :persistent="loading"
  >
    <v-card>
      <v-card-title class="text-h5">
        Cancel your subscription?
        <v-progress-circular
          indeterminate
          size="32"
          v-if="loading"
          class="ml-5"
        />
        <v-spacer />
        <v-btn icon @click="dialog = false" :disabled="loading">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        This will remove your access to the Nexus Connect Inbox.
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          class="btn-primary"
          @click="cancelSubscription"
          :disabled="loading"
        >
          Confirm Cancellation
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import client from "@/lib/ApiClient"

export default {
  name: "CancelDialog",

  props: ["value"],

  data() {
    return {
      loading: false
    }
  },

  computed: {
    dialog: {
      get() {
        return this.value
      },

      set(payload) {
        return this.$emit("input", payload)
      }
    }
  },

  methods: {
    async cancelSubscription() {
      this.loading = true
      await client.gateway.tenants.deleteTenant()
      window.location.reload()
    }
  }
}
</script>
