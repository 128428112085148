<template>
  <div>
    <v-card flat class="ma-4 rounded-20">
      <v-card-title class="title-font">
        SCANS &amp; FILES
        <v-spacer />
        <v-btn class="btn-primary" @click="fileUploadDialog = true">
          <v-icon class="mr-2">mdi-upload</v-icon>
          File Upload
        </v-btn>
      </v-card-title>
    </v-card>
    <v-card flat class="ma-4 rounded-20">
      <v-card-text>
        <v-row>
          <v-col cols="2">
            <v-list
              dense
              class="transparent pa-0"
              v-for="(category, index) in categories"
              :key="index"
            >
              <v-list-item
                class="mb-2 custom-grey"
                :input-value="selectedCategory === category.value"
                @click="selectedCategory = category.value"
              >
                <v-list-item-content class="title-font">
                  <v-list-item-title class="text-wrap">
                    {{ category.title }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-wrap">
                    {{ category.subtitle }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-chip
                  small
                  :color="fileCount(category.value) > 0 ? 'primary' : ''"
                  class="rounded-10"
                >
                  {{ fileCount(category.value) }}
                </v-chip>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col cols="10">
            <v-card
              flat
              class="rounded-10 overflow-y-auto custom-grey"
              max-height="65vh"
            >
              <v-card-title v-if="!selectedCategory">
                <v-icon class="mr-2">mdi-information</v-icon>
                Click on a file to tag a category
              </v-card-title>
              <v-card-text>
                <v-row v-if="!filteredFiles.length && loading">
                  <v-col cols="3" v-for="index in 4" :key="index">
                    <v-skeleton-loader type="image" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    class="text-center"
                    v-if="!filteredFiles.length && !loading"
                  >
                    No files found
                  </v-col>
                  <v-col
                    cols="3"
                    v-for="(file, index) in filteredFiles"
                    :key="index"
                  >
                    <v-card
                      flat
                      class="rounded-10 d-flex flex-column"
                      height="100%"
                    >
                      <v-row align="start" no-gutters>
                        <v-col align="end">
                          <v-btn
                            icon
                            class="rounded-10"
                            @click.stop="() => (viewFile = file)"
                          >
                            <v-icon>mdi-arrow-expand-all</v-icon>
                          </v-btn>
                          <v-btn
                            icon
                            class="rounded-10"
                            @click.stop="() => (selectedDeleteFile = file)"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-row align="center">
                        <v-col align="center">
                          <v-img
                            v-if="fileType(file) === 'image'"
                            height="150"
                            contain
                            :src="file.url"
                          />
                          <ModelViewer
                            v-if="fileType(file) === 'model'"
                            height="150"
                            width="270"
                            :filename="file.file_name"
                            :url="file.url"
                          />
                          <v-card
                            v-if="
                              fileType(file) === 'download' ||
                              fileType(file) === 'html'
                            "
                            flat
                            max-height="150"
                          >
                            <v-card-text class="text-center">
                              <v-icon>mdi-information</v-icon>
                              Cannot Preview File
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                      <v-card-text class="text-center">
                        <p class="off rounded-10">
                          {{ file.file_name }}
                        </p>
                        <div>
                          <small>
                            <i>Click on chip to categorise</i>
                          </small>
                        </div>
                        <v-chip
                          small
                          :color="!!file.file_category ? 'success' : ''"
                          class="rounded-10"
                          @click="selectedFile = file"
                        >
                          <div class="text-truncate">
                            {{
                              prettyFileCategory(file.file_category) ||
                              "Uncategorised"
                            }}
                          </div>
                        </v-chip>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <FileUploadModal
      v-model="fileUploadDialog"
      :categories="categories"
      :loading="loading"
      @upload="uploadFiles"
    />
    <CategoryModal
      v-model="categorySelectDialog"
      :files="files"
      :categories="categories"
      :loading="loading"
      @setCategory="setFileCategory"
    />
    <DeleteModal
      v-model="deleteDialog"
      :loading="loading"
      @delete="deleteOrderFile"
    />
    <FileViewer v-model="viewFile" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import client from "@/lib/ApiClient"
import clientOptions from "@/plugins/axios/client_options"
import FileUploadModal from "./ScansAndFiles/FileUploadModal"
import CategoryModal from "./ScansAndFiles/CategoryModal"
import DeleteModal from "./ScansAndFiles/DeleteModal"
import ModelViewer from "@/components/shared/ModelViewer.vue"
import FileViewer from "@/components/shared/FileViewer.vue"

export default {
  name: "ScansAndFiles",

  components: {
    CategoryModal,
    DeleteModal,
    FileUploadModal,
    ModelViewer,
    FileViewer
  },

  watch: {
    orderFiles(value, prevValue) {
      this.populateOrderFiles(value)
      if (value && prevValue === null) return (this.loading = false)
    },

    selectedFile(value) {
      if (value) {
        this.categorySelectDialog = true
      }
    },

    selectedDeleteFile(value) {
      if (value) {
        this.deleteDialog = true
      }
    },

    categorySelectDialog(value) {
      if (!value) {
        this.selectedFile = ""
      }
    },

    deleteDialog(value) {
      if (!value) {
        this.selectedDeleteFile = ""
      }
    }
  },

  data() {
    return {
      files: [],
      loading: null,
      categorySelectDialog: false,
      deleteDialog: false,
      fileUploadDialog: false,
      selectedCategory: "",
      selectedFile: "",
      selectedDeleteFile: "",
      viewFile: null,
      categories: [
        {
          title: "All Uncategorised Files",
          subtitle: "",
          value: ""
        },
        {
          title: "Scan Gauge Scans",
          subtitle: "",
          value: "scan_gauge_scan"
        },
        {
          title: "Existing Prosthesis",
          subtitle: "In-Situ Scan",
          value: "existing_prosthesis_in_situ_scan"
        },
        {
          title: "Tissue of Restoring Arch",
          subtitle: "Using Narrow Scan Bodies",
          value: "tissue_of_restoring_arch_using_narrow_scan_bodies"
        },
        {
          title: "Existing Prosthesis Extra-Orally 360",
          subtitle: "Using Scan Analogs",
          value: "existing_prosthesis_extra_orally_360_using_scan_analogs"
        },
        {
          title: "Antagonist Dentition",
          subtitle: "",
          value: "antagonist_dentition"
        },
        {
          title: "Bite Scan",
          subtitle: "",
          value: "bite_scan"
        },
        {
          title: "Full-Face Smiling",
          subtitle: "With Relaxed Bite",
          value: "full_face_smiling_with_relaxed_bite"
        },
        {
          title: "Full-Face Smiling",
          subtitle: "With Relaxed Bite using Retractors",
          value: "full_face_smiling_with_relaxed_bite_using_retractors"
        },
        {
          title: "Tooth Design",
          subtitle: "",
          value: "tooth_design"
        },
        {
          title: "Tissue of Restoring Arch",
          subtitle: "",
          value: "tissue_of_restoring_arch"
        },
        {
          title: "Abutment Analog Positions",
          subtitle: "",
          value: "abutment_analog_positions"
        }
      ]
    }
  },

  computed: {
    ...mapGetters(["selectedOrder", "orderFiles", "country"]),

    filteredFiles() {
      if (!this.selectedCategory) {
        return this.files.filter((file) => !file.file_category)
      }
      return this.files.filter(
        (file) => file.file_category === this.selectedCategory
      )
    }
  },

  methods: {
    ...mapActions(["triggerReload"]),

    populateOrderFiles(files) {
      for (const remoteFile of files) {
        const localFile = this.files.find(
          (localFile) => localFile.uid === remoteFile.uid
        )
        if (!localFile) {
          this.files.push(remoteFile)
        } else {
          if (localFile.file_category !== remoteFile.category) {
            localFile.file_category = remoteFile.file_category
          }
        }
      }
      for (const localFile of this.files) {
        if (
          !files.map((remoteFile) => remoteFile.uid).includes(localFile.uid)
        ) {
          const index = this.files
            .map((orphanedFile) => orphanedFile.uid)
            .indexOf(localFile.uid)
          this.files.splice(index, 1)
        }
      }
    },

    async uploadFiles(fileMetadata, files) {
      this.loading = true

      const oftResponse = await this.oftAddFiles(files)

      const payload = []
      const promises = []
      oftResponse.data.forEach((item, index) => {
        promises.push(
          this.uploadToGoogleStorage(files[index], item.oneTimeUploadLink)
        )

        payload.push({
          file_name: item.fileName,
          file_category: fileMetadata.fileCategory,
          file_type: fileMetadata.fileType,
          oft_file_uid: item.uid
        })
      })

      promises.push(
        client.instance.orders.addFiles(this.selectedOrder.uid, payload)
      )
      await Promise.all(promises)

      this.triggerReload("files")
      this.loading = false
    },

    async oftAddFiles(files) {
      const payload = files.map((file) => {
        return {
          fileName: file.name,
          fileReferences: [
            {
              entityType: "NCLABS_ORDER",
              entityIdentification: this.selectedOrder.uid
            }
          ]
        }
      })
      try {
        const response = await this.$axios.post(
          `${clientOptions.oftURL}/upload/${this.country}`,
          payload
        )
        return response
      } catch (error) {
        console.error(error)
      }
    },

    async uploadToGoogleStorage(file, uploadLink) {
      try {
        const response = await fetch(uploadLink, {
          method: "PUT",
          headers: {
            "Content-Type": "application/octet-stream"
          },
          body: file
        })
        return response
      } catch (error) {
        console.error(error)
      }
    },

    async fetchOrderFiles() {
      if (this.orderFiles === null) this.loading = true
      this.triggerReload("files")
    },

    async setFileCategory(value) {
      this.loading = true
      await client.instance.orders.updateFileCategory(
        this.selectedOrder.uid,
        this.selectedFile.uid,
        value
      )
      this.fetchOrderFiles()
      this.categorySelectDialog = false
      this.loading = false
    },

    async deleteOrderFile() {
      this.loading = true
      await client.instance.orders.deleteOrderFile(
        this.selectedOrder.uid,
        this.selectedDeleteFile.uid
      )
      this.fetchOrderFiles()
      this.deleteDialog = false
      this.loading = false
    },

    fileCount(category) {
      return this.files.filter((file) => file.file_category === category).length
    },

    fetchFileUrl(category) {
      return this.files.filter((file) => file.file_category === category)[0].url
    },

    fileType(file) {
      if (!file?.file_name) {
        return
      }
      const fileExt = file.file_name.toLowerCase().split(".").pop()
      switch (fileExt) {
        case "html":
          return "html"
        case "jpg":
        case "jpeg":
        case "png":
        case "svg":
          return "image"
        case "ply":
        case "stl":
          return "model"
        default:
          return "download"
      }
    },

    prettyFileCategory(value) {
      if (!value) {
        return
      }
      const category = this.categories.find(
        (category) => category.value === value
      )

      return `${category.title} ${category.subtitle}`
    }
  },

  mounted() {
    this.fetchOrderFiles()
  }
}
</script>
