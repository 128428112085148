<template>
  <div>
    <div class="text-center mt-5 title-font" v-if="change !== 'CURRENT'">
      <div class="mb-2" v-if="!scheduled">
        <v-progress-circular
          v-if="!upgradeCost && change === 'UPGRADE'"
          indeterminate
        />
        <span
          class="pricing-card-price"
          v-if="upgradeCost && change === 'UPGRADE'"
        >
          ${{ Math.round(upgradeCost).toLocaleString() }}
        </span>
        <span class="pricing-card-price" v-if="change === 'DOWNGRADE'">
          $0
        </span>
        <span> today </span>
      </div>
      <div class="mb-2">
        <span class="pricing-card-price" v-if="scheduled"> SCHEDULED </span>
      </div>
      <div>
        <span v-if="!scheduled"> then </span>
        <span class="black--text font-weight-bold" style="font-size: 16px">
          ${{ pricing?.subscription_price.toLocaleString() }}
        </span>
        <span v-if="pricing.renewal_frequency === 'monthly'">/month </span>
        <span v-if="pricing.renewal_frequency === 'yearly'">/year </span>
      </div>
    </div>
    <div class="ma-5">
      <v-btn
        v-if="change !== 'CURRENT'"
        :class="[scheduled ? 'btn-primary-light' : 'btn-primary', 'rounded-10']"
        block
        large
        text
        @click="$emit('selection')"
        :disabled="loading"
      >
        {{ scheduled ? "CANCEL" : null }}
        {{ change }}
      </v-btn>
      <div
        v-if="change === 'CURRENT'"
        class="elevation-0 title-font text-center d-flex align-center flex-column justify-start"
        height="122px"
        style="text-transform: uppercase; height: 122px"
        disabled
      >
        <span> Your </span>
        <span class="black--text my-2 font-weight-bold" style="font-size: 28px">
          Current
        </span>
        <span> Subscription </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  props: ["pricing", "change", "loading"],

  computed: {
    ...mapGetters(["subscriptionSchedule", "subscription", "plans"]),

    scheduled() {
      return this.pricing.code === this.subscriptionSchedule
    },

    upgradeCost() {
      const currentPlanPrice = this.plans.find(
        (p) => p.code == this.subscription.plan_code
      ).subscription_price
      return this.pricing.subscription_price - currentPlanPrice
    }
  }
}
</script>
