<template>
  <div>
    <OpenForwardedOrders @openOrder="handleOrderClick" class="mb-4 mt-4" />
    <v-card flat class="ma-4 ml-0 rounded-20 overflow-hidden">
      <template v-if="partialOrders.length">
        <v-card-title class="title-font">
          PARTIALLY COMPLETE ORDERS
        </v-card-title>
        <v-card-text> Click on an order to continue. </v-card-text>
        <v-simple-table v-if="partialOrders.length">
          <template v-slot:default>
            <thead>
              <tr>
                <th>Type</th>
                <th>Created</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="order in partialOrders"
                :key="order.uid"
                style="cursor: pointer"
                @click="handleOrderClick(order)"
              >
                <td>
                  <v-icon size="40">
                    {{ determineIconFromProduct(order.product) }}
                  </v-icon>
                </td>
                <td>
                  {{ order.created_at | luxon({ output: "short" }) }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </template>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import OpenForwardedOrders from "@/components/shared/OpenForwardedOrders"

export default {
  name: "ExistingAndPartialOrders",

  components: {
    OpenForwardedOrders
  },

  computed: {
    ...mapGetters(["selectedOrder", "orderForwardedOrders"]),

    partialOrders() {
      return this.orderForwardedOrders.filter((o) => !o.submitted_at) || []
    }
  },

  methods: {
    ...mapActions(["setSelectedForwardedOrder", "triggerReload"]),

    determineIconFromProduct(product_code) {
      switch (product_code) {
        case "BAR":
          return "$milledbar"
        case "DESIGN":
          return "$toothdesigns"
      }
    },

    async handleOrderClick(order) {
      this.setSelectedForwardedOrder(order)
      this.triggerReload("forwardedOrder")
    }
  }
}
</script>
