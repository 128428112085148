<template>
  <div>
    <v-btn
      class="elevation-0 rounded-10 grey-border"
      width="100%"
      height="86"
      @click="dialog = true"
    >
      <div class="d-flex flex-column">
        <span>
          <v-icon class="mr-1">$upload</v-icon>
          Choose Existing
        </span>
        <i style="font-size: 10px; text-transform: none"> Click to upload </i>
      </div>
    </v-btn>
    <ExistingFileSelector
      v-model="selectedFile"
      :category="category"
      :open="dialog"
      @close="dialog = false"
    />
  </div>
</template>

<script>
import ExistingFileSelector from "./ExistingFileUploader/ExistingFileSelector.vue"

export default {
  name: "ExistingFileUploader",

  props: ["value", "category"],

  components: { ExistingFileSelector },

  watch: {
    selectedFile() {
      this.dialog = false
    }
  },

  data() {
    return {
      dialog: false
    }
  },

  computed: {
    selectedFile: {
      get() {
        return this.value
      },

      set(value) {
        this.$emit("input", value)
      }
    }
  }
}
</script>
