<template>
  <v-card flat class="card-bg rounded-20 mt-5 pb-5">
    <v-toolbar class="transparent" flat dense>
      <v-toolbar-title class="title-font"> INVOICE HISTORY </v-toolbar-title>
    </v-toolbar>
    <v-card flat class="mx-4 rounded-10">
      <v-card-text v-if="loading" class="text-center">
        <v-progress-circular indeterminate />
      </v-card-text>
      <v-data-table
        :headers="headers"
        :items="invoices || []"
        :items-per-page="5"
        v-if="!loading"
      >
        <template v-slot:[`item.invoice_number`]="{ item }">
          {{ item.invoice_number.slice(9) }}
        </template>
        <template v-slot:[`item.invoiced_at`]="{ item }">
          {{ item.invoiced_at | luxon({ output: "date_short" }) }}
        </template>
        <template v-slot:[`item.amount`]="{ item }">
          $ {{ item.amount }}
        </template>
        <template v-slot:[`item.pdf`]="{ item }">
          <a :href="item.pdf">PDF</a>
        </template>
        <template v-slot:[`item.url`]="{ item }">
          <a :href="item.url">HTML</a>
        </template>
      </v-data-table>
    </v-card>
  </v-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex"

export default {
  name: "InvoiceHistory",

  data() {
    return {
      loading: false,
      headers: [
        {
          text: "Invoice ID",
          value: "invoice_number"
        },
        {
          text: "Date",
          value: "invoiced_at"
        },
        {
          text: "Amount",
          value: "amount"
        },
        {
          text: "PDF",
          value: "pdf"
        },
        {
          text: "HTML",
          value: "url"
        }
      ]
    }
  },

  watch: {
    invoices(val) {
      if (val) this.loading = false
    }
  },

  computed: {
    ...mapGetters(["invoices"])
  },

  methods: {
    ...mapActions(["triggerReload"])
  },

  mounted() {
    this.loading = true
    if (this.invoices) this.loading = false
    else this.triggerReload("invoices")
  }
}
</script>
