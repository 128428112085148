<template>
  <div
    class="d-flex align-center pa-10"
    style="gap: 20px; position: relative; background: rgba(0, 0, 0, 0.01)"
  >
    <v-btn
      icon
      style="position: absolute; top: 2px; right: 2px"
      color="white"
      @click="close"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>

    <div class="frame" @click.stop>
      <div class="systemBar" />

      <div class="appBar">
        <v-img
          :src="
            settings?.logo_url || require('@/assets/nexus-connect-logo.svg')
          "
          :max-height="`${(64 - 14) / scalingFactor}px`"
          class="flex-grow-0"
          contain
        />
      </div>

      <div class="main">
        <v-card class="previewCard">
          <div class="title-font">PLATFORM PREVIEW</div>

          <div class="d-flex align-center">
            <v-icon
              :size="128 / scalingFactor"
              :color="colours.primary"
              class="px-6"
            >
              mdi-feature-search
            </v-icon>
            <div class="text">
              <div class="mb-2">
                This is a theme preview for
                <strong> {{ currentTenant.name }}</strong
                >.
              </div>
              <div>Click the buttons below to modify your theme.</div>
            </div>
          </div>

          <div class="settingsGrid">
            <div>
              <v-icon size="36" :color="colours.primary" class="mr-3">
                mdi-help-circle
              </v-icon>
              <v-btn
                class="elevation-0 primary-button"
                @click="handlePick('primary')"
              >
                Primary
              </v-btn>
            </div>

            <div>
              <v-btn
                class="elevation-0 secondary-button"
                :color="colours.secondary"
                @click="handlePick('secondary')"
              >
                Secondary
              </v-btn>
            </div>

            <div>
              <v-icon size="36" :color="colours.success" class="mr-3">
                mdi-check-circle
              </v-icon>
              <v-btn
                class="elevation-0 success-button"
                @click="handlePick('success')"
              >
                Success
              </v-btn>
            </div>

            <div>
              <v-btn
                class="elevation-0 background-button"
                @click="handlePick('background')"
              >
                Background
              </v-btn>
            </div>
          </div>
        </v-card>

        <div class="branding">
          <v-img
            :src="
              settings?.logo_url || require('@/assets/nexus-connect-logo.svg')
            "
            width="360"
            contain
            style="flex-grow: 0"
          />
          <span
            class="title-font mt-4"
            style="font-size: 17px; letter-spacing: 0.25ch"
          >
            {{ settings.tenant_name }}
          </span>
        </div>
      </div>
    </div>

    <div @click.stop class="overflow-hidden">
      <v-card class="d-flex flex-column" v-if="settingDialog">
        <div
          v-for="(setting, index) in settingCategory"
          :key="setting.key"
          class="px-5"
        >
          <v-card-title :class="['title-font', index > 0 ? null : 'pt-3']">
            {{ setting.title }}
            <v-spacer />
            <v-btn
              icon
              small
              v-if="settings.colours[setting.key] !== savedColours[setting.key]"
              @click="settings.colours[setting.key] = savedColours[setting.key]"
            >
              <v-icon small>mdi-restart</v-icon>
            </v-btn>
          </v-card-title>
          <v-color-picker
            v-if="setting.type === 'colour'"
            v-model="settings.colours[setting.key]"
            class="mx-auto"
            dot-size="25"
            mode="hexa"
            canvas-height="100"
            width="275px"
          />
          <div
            class="d-flex align-center mt-6"
            style="position: relative; width: 275px"
            v-if="setting.type === 'direction'"
          >
            <v-slider
              @change="(v) => handleSlider(setting.key, v)"
              :value="settings.colours[setting.key].replace('deg', '')"
              thumb-label="always"
              :thumb-size="25"
              min="0"
              max="315"
              step="45"
              class="mb-2"
              style="z-index: 10"
              hide-details
            />
            <v-icon
              size="48"
              class="flex-grow-0"
              :style="`transform: rotate(${settings.colours[setting.key]});`"
            >
              mdi-arrow-down
            </v-icon>
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<style scoped>
.frame {
  --scaling-factor: v-bind(scalingFactor);
  width: calc(1920px / var(--scaling-factor));
  height: calc(1000px / var(--scaling-factor));
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  background-color: white;
}
.systemBar {
  width: 100%;
  height: calc(24px / var(--scaling-factor));
  background-color: v-bind(colours.secondary);
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.appBar {
  width: 100%;
  height: calc(64px / var(--scaling-factor));
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.main {
  background: linear-gradient(
    v-bind(colours.backgroundDir),
    v-bind(colours.background1) 0%,
    v-bind(colours.background1) 11%,
    v-bind(colours.background2) 100%
  );
  flex-grow: 1;
  width: 100%;
  display: flex;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.previewCard {
  /* border-radius: calc(20px / var(--scaling-factor)); */
  width: calc(800px / var(--scaling-factor));
  height: calc(600px / var(--scaling-factor));
  margin-top: calc(140px / var(--scaling-factor));
  margin-left: calc(40px / var(--scaling-factor));
  padding: calc(8px / var(--scaling-factor));
}
div.title-font {
  font-size: calc(20px * 1.5 / var(--scaling-factor));
  padding: calc(18px / var(--scaling-factor));
}
.text {
  font-size: calc(17px * 1.5 / var(--scaling-factor));
}

.settingsGrid {
  margin-top: calc(80px / var(--scaling-factor));
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  align-items: center;
  padding-inline: calc(50px / var(--scaling-factor));
}
.primary-button {
  color: v-bind(textColours.primary);
  background: linear-gradient(
    90deg,
    v-bind(colours.primary),
    v-bind(colours.primary2)
  );
}
.secondary-button {
  color: v-bind(textColours.secondary);
}
.success-button {
  color: v-bind(textColours.success);
  background: linear-gradient(
    90deg,
    v-bind(colours.success),
    v-bind(colours.success2)
  );
}
.background-button {
  color: v-bind(textColours.background);
  background: linear-gradient(
    v-bind(colours.backgroundDir),
    v-bind(colours.background1) 0%,
    v-bind(colours.background1) 11%,
    v-bind(colours.background2) 100%
  );
}
.branding {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>

<script>
import { mapGetters } from "vuex"
import { getTextColor } from "@/utils"

const settingMap = {
  primary: [
    {
      title: "Primary",
      key: "primary",
      type: "colour"
    },
    {
      title: "Primary Button Gradient",
      key: "primary2",
      type: "colour"
    }
  ],
  secondary: [
    {
      title: "Secondary",
      key: "secondary",
      type: "colour"
    }
  ],
  success: [
    {
      title: "Success",
      key: "success",
      type: "colour"
    },
    {
      title: "Success Button Gradient",
      key: "success2",
      type: "colour"
    }
  ],
  background: [
    {
      title: "Background",
      key: "background1",
      type: "colour"
    },
    {
      title: "Background Gradient",
      key: "background2",
      type: "colour"
    },
    {
      title: "Gradient Direction",
      key: "backgroundDir",
      type: "direction"
    }
  ]
}

export default {
  name: "ThemePreview",

  props: ["value", "savedColours"],

  data() {
    return {
      scalingFactor: 2,
      settingCategory: null,
      settingMap
    }
  },

  computed: {
    ...mapGetters(["currentTenant"]),
    settings: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      }
    },

    colours() {
      const colours = { ...this.settings.colours }
      for (let key in colours) {
        if (colours[key].length === 9 && /^.{7}[fF]{2}$/.test(colours[key]))
          colours[key] = colours[key].slice(0, 7)
      }
      return colours
    },

    textColours() {
      return {
        primary: getTextColor(this.colours.primary),
        secondary: getTextColor(this.colours.secondary),
        success: getTextColor(this.colours.success),
        background: getTextColor(this.colours.background1)
      }
    },

    settingDialog: {
      get() {
        return !!this.settingCategory
      },
      set() {
        this.settingCategory = null
      }
    }
  },

  methods: {
    handlePick(pick) {
      if (this.settingCategory === settingMap[pick])
        return (this.settingCategory = null)
      this.settingCategory = settingMap[pick]
    },

    handleSlider(key, value) {
      this.settings.colours[key] = `${value}deg`
    },

    close() {
      this.$emit("close")
    }
  }
}
</script>
