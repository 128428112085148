<template>
  <v-card flat class="card-bg rounded-20">
    <v-toolbar class="transparent" flat dense>
      <v-toolbar-title class="title-font"> ORDER FORM PREVIEW </v-toolbar-title>
    </v-toolbar>

    <v-card-text
      v-if="initialising && !questions.length"
      class="pa-5 text-center"
    >
      <v-progress-circular indeterminate />
    </v-card-text>

    <v-card-text v-if="!initialising && !questions.length" class="pa-5">
      There are no Questions for this Order Form
    </v-card-text>
    <v-card flat v-if="questions.length" class="mx-4 pa-4 rounded-10">
      <v-card
        v-for="(question, index) in questions"
        :key="index"
        flat
        class="rounded-10"
      >
        <v-card-title :class="['mx-0 px-0', !index && 'pt-0']">
          <v-icon small v-if="question.mandatory" class="mr-1"> * </v-icon>
          {{ question.name || "Question Preview" }}
        </v-card-title>

        <v-card-subtitle>
          {{ question.description }}
        </v-card-subtitle>

        <v-text-field
          v-if="question.widget === 'text_field' || !question.widget"
          label="Answer"
          outlined
          dense
          hide-details
          disabled
        />
        <v-textarea
          v-if="question.widget === 'text_area'"
          label="Answer"
          rows="1"
          hide-details
          auto-grow
          outlined
          dense
          disabled
        />
        <v-select
          v-if="question.widget === 'drop_down_picker'"
          label="Answer"
          :items="availableResponses(question)"
          outlined
          dense
          hide-details
          :menu-props="{ rounded: '10' }"
        />
        <v-form v-if="question.widget === 'tooth_picker'">
          <MultiUnitConfirmation
            v-model="dummyTeeth"
            :responses="availableResponses(question)"
            :interactive="true"
          />
        </v-form>
        <DatePicker v-if="question.widget === 'date_picker'" />
      </v-card>
    </v-card>
    <v-card-actions> </v-card-actions>
  </v-card>
</template>

<script>
import MultiUnitConfirmation from "../shared/MultiUnitConfirmation.vue"
import DatePicker from "@/components/shared/Widgets/DatePicker"

export default {
  props: ["questions", "initialising"],

  components: { MultiUnitConfirmation, DatePicker },

  data() {
    return {
      dummyTeeth: []
    }
  },

  methods: {
    availableResponses(question) {
      return question.metadata?.responses?.map((response) => {
        return {
          text: response.name,
          value: response.name
        }
      })
    }
  }
}
</script>
