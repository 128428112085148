<template>
  <v-data-table
    :headers="headers"
    :items="paymentMethods"
    hide-default-footer
    class="rounded-10"
    @click:row="emitSelection"
  >
    <template v-slot:no-data v-if="loading">
      <v-progress-circular indeterminate />
    </template>
    <template v-slot:[`item.brand`]="{ item }">
      <v-icon v-if="item.brand === 'visa'"> $visa </v-icon>
      <v-icon v-else-if="item.brand === 'mastercard'"> $mastercard </v-icon>
      <v-icon v-else-if="item.brand === 'amex'"> $amex </v-icon>
      <v-icon v-else>mdi-credit-card</v-icon>
      <strong class="mx-2">
        {{ capitalise(item.brand) }}
      </strong>
      <v-chip v-if="item.default" color="primary" label x-small>
        Default Payment
      </v-chip>
    </template>
    <template v-slot:[`item.last4`]="{ item }">
      <strong> **** **** **** {{ item.last4 }} </strong>
    </template>
    <template v-slot:[`item.expiry`]="{ item }">
      Expires
      {{
        new Date(0, item.exp_month - 1).toLocaleString("en-US", {
          month: "short"
        })
      }}
      {{ item.exp_year }}
    </template>
  </v-data-table>
</template>

<script>
import { capitalise } from "@/utils"

export default {
  name: "PaymentMethodTable",

  props: ["paymentMethods", "loading"],

  data() {
    return {
      headers: [
        {
          text: "Brand",
          sortable: false,
          value: "brand"
        },
        {
          text: "Last 4 Digits",
          sortable: false,
          value: "last4"
        },
        {
          text: "Expiry",
          sortable: false,
          value: "expiry"
        }
      ]
    }
  },

  methods: {
    capitalise,
    emitSelection(item) {
      this.$emit("select", item)
    }
  }
}
</script>
