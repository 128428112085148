<template>
  <div>
    <v-card flat class="ma-4 rounded-20" v-if="fullmode">
      <v-card-title class="title-font"> ORDER FORM </v-card-title>
    </v-card>

    <v-card
      flat
      :class="{
        'mt-4': true,
        'ml-4': true,
        'mr-4': fullmode,
        'rounded-20': true
      }"
    >
      <v-card-title class="title-font pb-0" v-if="!fullmode">
        ORDER FORM
        <v-spacer />
        <v-tooltip top class="rounded-10">
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="$emit('fullmode')" icon v-bind="attrs" v-on="on">
              <v-icon> mdi-arrow-expand-all </v-icon>
            </v-btn>
          </template>
          <span>Expand Order Form</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <v-list>
          <v-list-item
            two-line
            v-for="(question, index) in selectedOrder.responses"
            :key="index"
          >
            <v-list-item-content>
              <v-list-item-title>{{
                question.name.toUpperCase()
              }}</v-list-item-title>
              <v-list-item-subtitle
                v-if="question.widget !== 'tooth_picker'"
                class="font-weight-bold black--text"
              >
                {{ question.value }}
              </v-list-item-subtitle>
              <ToothPicker
                v-if="question.widget === 'tooth_picker'"
                v-model="teeth"
                :interactive="false"
                style="max-height: 300px"
              />
              <v-simple-table
                dense
                v-if="
                  question.widget === 'tooth_picker' &&
                  question.value.length > 0
                "
              >
                <thead>
                  <tr>
                    <th>Tooth</th>
                    <th>Implant Platform</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(tooth, index) in question.value" :key="index">
                    <td>{{ tooth.tooth }}</td>
                    <td>{{ tooth.implant_platform.name }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import ToothPicker from "@/components/shared/ToothPicker"

export default {
  name: "OrderForm",

  props: ["fullmode"],

  components: { ToothPicker },

  data() {
    return {
      teeth: []
    }
  },

  computed: {
    ...mapGetters(["selectedOrder"])
  },

  mounted() {
    this.toothPickerHandler(
      this.selectedOrder.responses.find(
        (item) => item.widget === "tooth_picker"
      )?.value
    )
  },

  methods: {
    toothPickerHandler(response) {
      this.teeth = response?.map((item) => item.tooth)
    }
  }
}
</script>
