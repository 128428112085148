<template>
  <v-card class="rounded-20 ml-4 mt-7" flat>
    <v-card-title class="title-font">
      SELECT ABUTMENT EXPORT
      <v-spacer />
      <div
        v-if="showErrors && !abutmentAnalogPositions"
        class="error--text text-body-2"
      >
        <v-icon color="error" class="mr-2">mdi-close-circle</v-icon>
        <span> Please select an Abutment Export </span>
      </div>
    </v-card-title>
    <v-card class="rounded-10 mx-4 pa-2" outlined flat>
      <v-simple-table v-if="selectedOrderExports?.length">
        <template v-slot:default>
          <thead>
            <tr>
              <th>Scan Body</th>
              <th>Healing Cap</th>
              <th class="text-right">Exported At</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="exportItem in selectedOrderExports"
              :key="exportItem.uid"
              style="cursor: pointer"
              @click="abutmentAnalogPositions = exportItem.uid"
              :class="[
                {
                  'gradient-bg-2': abutmentAnalogPositions === exportItem.uid
                }
              ]"
              v-ripple
            >
              <td>
                {{ libraryName(exportItem.scan_body_component_id) }}
              </td>
              <td>
                {{ libraryName(exportItem.healing_cap_component_id) }}
              </td>
              <td class="text-right">
                {{ exportItem.created_at | luxon({ output: "short" }) }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import client from "@/lib/ApiClient"

export default {
  name: "SelectAbutmentExport",

  props: ["value"],

  inject: ["form"],

  data() {
    return {
      rules: [(v) => !!v || "Required"],
      showErrors: false
    }
  },

  computed: {
    ...mapGetters([
      "selectedOrder",
      "selectedOrderExports",
      "exportComponents"
    ]),

    abutmentAnalogPositions: {
      get() {
        return this.value
      },
      set(value) {
        this.showErrors = false
        this.$emit("input", value)
      }
    }
  },

  methods: {
    ...mapActions(["setExportComponents"]),

    validate() {
      if (!this.abutmentAnalogPositions) this.showErrors = true
      return !!this.abutmentAnalogPositions
    },

    reset() {
      this.$emit("input", null)
    },

    libraryName(componentId) {
      return this.exportComponents?.find((c) => c.id === parseInt(componentId))
        ?.name
    },

    async fetchExportComponents() {
      this.loading = true
      this.setExportComponents(
        await client.gateway.components.fetchExportComponents()
      )
      this.loading = false
    }
  },

  async mounted() {
    if (!this.exportComponents) this.fetchExportComponents()
  },

  created() {
    this.form.register(this)
  }
}
</script>
