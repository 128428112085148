<template>
  <v-card flat class="rounded-20">
    <v-card-title>
      <v-icon class="mr-2">mdi-office-building</v-icon>Select a Business
    </v-card-title>
    <v-card-text>
      <p>Your identity is associated with more than one registered business.</p>
      <p>
        Please select the business you'd like to continue with from the list
        below:
      </p>
      <v-list-item-group>
        <v-list-item
          v-for="(tenant, index) in tenants"
          :key="index"
          two-line
          @click="selectTenant(tenant)"
        >
          <v-list-item-content>
            <v-list-item-title>
              {{ tenant.name }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "SelectTenant",

  props: ["tenants"],

  methods: {
    selectTenant(tenant) {
      this.$emit("onContinue", tenant.uid)
    }
  }
}
</script>
