var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"pb-2 d-flex flex-column align-center rounded-20",attrs:{"flat":"","height":"100%"}},[_c('v-card-subtitle',{staticClass:"title-font black--text text-center"},[_vm._v(" SELECTED GAUGE ")]),(_vm.selectedKitGauge)?_c('v-img',{attrs:{"src":_vm.selectedKitGauge.src,"max-height":"450","width":"100","contain":""}}):_vm._e(),_c('v-spacer'),_c('v-card-text',{staticClass:"py-0"},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{class:[
          {
            'btn-primary': _vm.selectedKitGauge && _vm.selectedScanGaugeIndex !== -1
          }
        ],staticStyle:{"min-height":"28px"},attrs:{"disabled":!_vm.selectedKitGauge || _vm.selectedScanGaugeIndex === -1},on:{"click":function($event){return _vm.$emit('confirm')}}},[_c('v-list-item-content',{staticClass:"py-0 title-font",class:{
            'white--text': _vm.selectedKitGauge && _vm.selectedScanGaugeIndex !== -1
          }},[_c('span',{staticClass:"text-center"},[_vm._v(" CONFIRM SELECTION ")])])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }