<template>
  <v-row>
    <v-col cols="6" class="py-0">
      <ExportAbutment @exported="onExport" @cancelled="$emit('cancelled')" />
    </v-col>
    <v-col cols="6" class="py-0">
      <v-card flat class="rounded-20 mr-4 mt-4">
        <v-card-text>
          <ExistingExports v-model="newExportUid" />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import ExportAbutment from "./ExportComponent/ExportAbutment.vue"
import ExistingExports from "./ExportComponent/ExistingExports.vue"

export default {
  name: "ExportComponent",

  data() {
    return {
      historyExports: [],
      newExportUid: null,
      loading: false
    }
  },

  components: {
    ExportAbutment,
    ExistingExports
  },

  computed: {
    ...mapGetters(["selectedOrder", "selectedOrderExports"])
  },

  methods: {
    ...mapActions(["triggerReload"]),

    onExport(newExportUid) {
      this.newExportUid = newExportUid
    }
  },

  mounted() {
    this.triggerReload("exports")
  }
}
</script>
