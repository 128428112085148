<template>
  <v-card flat>
    <v-card-title class="pb-0">
      <v-icon class="mr-2">mdi-database</v-icon>
      Where do you want to store your data?
      <v-chip v-if="error" color="error" class="ml-2">
        {{ error }}
      </v-chip>
    </v-card-title>
    <v-card-text>
      <v-radio-group
        hide-details
        v-for="(dataStorage, index) in instances"
        :key="index"
        v-model="selectedInstance"
      >
        <v-radio :value="dataStorage.uid">
          <template slot="label">
            <CountryFlag
              :country="dataStorage.country_code"
              class="mr-1"
              style="margin-bottom: 1px"
            />
            {{ dataStorage.name }}
          </template>
        </v-radio>
      </v-radio-group>
    </v-card-text>
  </v-card>
</template>

<script>
import CountryFlag from "vue-country-flag"
import { mapActions, mapGetters } from "vuex"
import client from "@/lib/ApiClient"

export default {
  name: "DataStorageSelector",

  props: ["value"],

  inject: ["form"],

  components: {
    CountryFlag
  },

  data() {
    return {
      dataStorages: [],
      error: null
    }
  },

  computed: {
    ...mapGetters(["instances"]),

    selectedInstance: {
      get() {
        return this.value
      },

      set(value) {
        if (value) {
          this.error = null
        }
        this.$emit("input", value)
      }
    }
  },

  methods: {
    ...mapActions(["setInstances"]),

    validate() {
      if (!this.selectedInstance) {
        this.error = "Must be selected"
      }
      return !!this.selectedInstance
    },

    async fetchInstances() {
      this.setInstances(await client.gateway.instances.fetchInstances())
    }
  },

  beforeMount() {
    if (this.instances.length === 0) this.fetchInstances()
  },

  created() {
    this.form.register(this)
  }
}
</script>
