<template>
  <v-card class="mr-4 rounded-20 mt-4" flat>
    <v-card-actions>
      <v-spacer />
      <v-btn class="btn-error" elevation="0" @click="$emit('back')">
        Cancel
      </v-btn>
      <v-btn
        class="btn-primary white--text my-4 mx-2"
        @click="$emit('submit')"
        :disabled="!isOwner && !barsAvailable"
      >
        Submit
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "SubmitOrCancel",

  computed: {
    ...mapGetters(["isOwner", "currentQuotas"]),

    barsAvailable() {
      const bars = this.currentQuotas.find((q) => q.quota_code === "bars")
      return bars.current
    }
  }
}
</script>
