<template>
  <v-card flat>
    <v-card-text
      v-if="initialising && !questions?.length"
      class="pa-5 text-center"
    >
      <v-progress-circular indeterminate />
    </v-card-text>
    <v-card-text
      v-if="!initialising && questions && !questions.length"
      class="pa-5"
    >
      There are no Questions for this Order Form
    </v-card-text>

    <v-card-text v-if="questions?.length && form.responses.length" class="pa-0">
      <v-card
        class="rounded-10"
        v-for="(question, index) in questions"
        :key="index"
        flat
      >
        <v-card-title :class="['mx-0', 'px-0', { 'pt-0': index === 0 }]">
          <v-icon small v-if="question.mandatory" class="mr-1"> </v-icon>
          {{ question.name }}
        </v-card-title>

        <v-card-subtitle>
          {{ question.description }}
        </v-card-subtitle>

        <v-text-field
          v-if="question.widget === 'text_field'"
          v-model="form.responses[index].value"
          :label="`Answer ${question.mandatory ? '(Required)' : ''}`"
          outlined
          dense
          hide-details="auto"
          :rules="question.mandatory ? rule : []"
        />
        <v-textarea
          v-if="question.widget === 'text_area'"
          v-model="form.responses[index].value"
          label="Answer"
          rows="1"
          hide-details="auto"
          auto-grow
          outlined
          dense
          :rules="question.mandatory ? rule : []"
        />
        <v-select
          v-if="question.widget === 'drop_down_picker'"
          v-model="form.responses[index].value"
          label="Answer"
          :items="availableResponses(question)"
          outlined
          dense
          hide-details="auto"
          :menu-props="{ rounded: '10' }"
          :rules="question.mandatory ? rule : []"
        />
        <v-card v-if="question.widget === 'tooth_picker'" flat>
          <MultiUnitConfirmation
            v-model="form.responses[index].value"
            :responses="availableResponses(question)"
            :interactive="true"
          />
        </v-card>
        <DatePicker
          v-model="form.responses[index].value"
          v-if="question.widget === 'date_picker'"
        />
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import DatePicker from "@/components/shared/Widgets/DatePicker.vue"
import MultiUnitConfirmation from "@/components/shared/MultiUnitConfirmation.vue"

export default {
  props: ["value"],

  components: {
    DatePicker,
    MultiUnitConfirmation
  },

  data() {
    return {
      initialising: false
    }
  },

  watch: {
    orderFormQuestions(val, prevVal) {
      if (val && prevVal === null) this.initialise()
    }
  },

  computed: {
    ...mapGetters(["orderFormQuestions"]),

    form: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      }
    },

    questions() {
      if (this.orderFormQuestions === null) return []
      return this.orderFormQuestions
    }
  },

  methods: {
    ...mapActions(["triggerReload"]),

    availableResponses(question) {
      return question.metadata?.responses?.map((response) => {
        return {
          text: response.name,
          value: response.name
        }
      })
    },

    initialise() {
      this.orderFormQuestions.forEach((question, index) => {
        this.form.responses.push({
          order: index + 1,
          name: question.name,
          description: question.description,
          widget: question.widget,
          value: question.widget === "tooth_picker" ? [] : ""
        })
      })
      this.initialising = false
    }
  },

  async mounted() {
    this.initialising = true
    this.triggerReload("questions")
    if (this.orderFormQuestions) this.initialise()
  }
}
</script>
