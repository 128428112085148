import { render, staticRenderFns } from "./MessageComponent.vue?vue&type=template&id=328e86bb"
import script from "./MessageComponent.vue?vue&type=script&lang=js"
export * from "./MessageComponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports