import { render, staticRenderFns } from "./ToothPicker.vue?vue&type=template&id=52a9094b"
import script from "./ToothPicker.vue?vue&type=script&lang=js"
export * from "./ToothPicker.vue?vue&type=script&lang=js"
import style0 from "./ToothPicker.vue?vue&type=style&index=0&id=52a9094b&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports