<template>
  <v-row style="width: 200px; font-size: small">
    <v-col>
      <v-img
        :src="heatmapScale"
        :max-width="(200 * 5) / 6"
        contain
        position="center center"
        class="mx-auto"
      />
      <v-row class="title-font">
        <v-col class="pb-0 px-0 text-center"> 0 </v-col>
        <v-col class="pb-0 px-0 text-center"> 20 </v-col>
        <v-col class="pb-0 px-0 text-center"> 50 </v-col>
        <v-col class="pb-0 px-0 text-center"> 80 </v-col>
        <v-col class="pb-0 px-0 text-center"> 100+ </v-col>
      </v-row>
      <v-row class="mt-2" style="font-size: x-small">
        <v-col class="title-font text-center pt-0">
          MICRONS (0.001<small>mm</small>)
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import heatmapScale from "@/assets/heatmap-scale.svg"
export default {
  name: "HeatmapScale",

  data() {
    return {
      heatmapScale
    }
  }
}
</script>
