<template>
  <v-hover v-slot="{ hover }">
    <div>
      <div v-if="loading" class="ma-4">
        <v-progress-circular indeterminate />
      </div>
      <div v-if="!loading">
        <v-card
          height="175"
          width="175"
          :class="['rounded-10', 'blue-border', { 'btn-primary': hover }]"
        >
          <v-card-text
            class="px-1 text-center text-subtitle-1 pb-0"
            :style="{
              color: hover ? 'white' : '#147BD1'
            }"
          >
            <strong> TOOTH DESIGN & MILLED BAR </strong>
          </v-card-text>
          <v-card-text
            class="pa-0 text-center font-weight-regular text-caption"
            :style="{
              color: hover ? 'white' : '#147BD1'
            }"
          >
            Using Osteon Tooth Design
          </v-card-text>
          <v-container fluid>
            <v-img
              v-if="hover"
              contain
              :src="
                require('@/assets/design-only-prosthesis-with-bar-white.png')
              "
              position="center center"
            />
            <v-img
              v-if="!hover"
              contain
              :src="require('@/assets/design-only-prosthesis-with-bar.png')"
              position="center center"
            />
          </v-container>
        </v-card>
        <v-card flat v-if="hover">
          <v-card-text class="text-left">
            <ul>
              <li>
                Design and milling of a Nexus bar with Osteon tooth design.
              </li>
              <li>
                Receive tooth design to either approve, or 3D print and use for
                Try-In.
              </li>
              <li>One design is approved, bar milled.</li>
              <li>
                Receive a milled bar and overlay file for lab milling/printing.
              </li>
            </ul>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </v-hover>
</template>

<script>
export default {
  name: "DesignAndBarOptions",

  props: ["loading"]
}
</script>
