var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card-text',{staticClass:"py-1"},[_c('v-row',{staticClass:"mb-3",attrs:{"no-gutters":""}},_vm._l((_vm.selectedGaugeLibraries),function(_,index){return _c('v-col',{key:index,staticClass:"my-1",attrs:{"align":"center"}},[_c('v-btn',{attrs:{"icon":"","elevation":"0"},on:{"click":function($event){return _vm.$emit('select', index)}}},[_c('v-avatar',{attrs:{"size":"32","color":index === _vm.selectedScanGaugeIndex
              ? 'primary'
              : _vm.selectedGaugeLibraries[index]
              ? 'success'
              : ''}},[(
              index === _vm.selectedScanGaugeIndex ||
              _vm.selectedGaugeLibraries[index]
            )?_c('span',{staticClass:"white--text"},[_vm._v(" "+_vm._s(index + 1)+" ")]):_vm._e(),(
              index !== _vm.selectedScanGaugeIndex &&
              !_vm.selectedGaugeLibraries[index]
            )?_c('span',[_vm._v(" "+_vm._s(index + 1)+" ")]):_vm._e()])],1)],1)}),1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"align":"center"}},[_c('v-btn',{class:[
          { 'rounded-10': true },
          { 'mb-2': true },
          { 'btn-primary': _vm.readyToComplete }
        ],attrs:{"elevation":"0","disabled":!_vm.readyToComplete},on:{"click":function($event){return _vm.$emit('confirm')}}},[_c('v-icon',[_vm._v("mdi-check")]),_vm._v(" Confirm Association ")],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }