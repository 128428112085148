<template>
  <v-card
    flat
    class="pb-2 d-flex flex-column align-center rounded-20"
    height="100%"
  >
    <v-card-subtitle class="title-font black--text text-center">
      SELECTED GAUGE
    </v-card-subtitle>
    <v-img
      v-if="selectedKitGauge"
      :src="selectedKitGauge.src"
      max-height="450"
      width="100"
      contain
    />
    <v-spacer />
    <v-card-text class="py-0">
      <v-list dense>
        <v-list-item
          :class="[
            {
              'btn-primary': selectedKitGauge && selectedScanGaugeIndex !== -1
            }
          ]"
          @click="$emit('confirm')"
          :disabled="!selectedKitGauge || selectedScanGaugeIndex === -1"
          style="min-height: 28px"
        >
          <v-list-item-content
            class="py-0 title-font"
            :class="{
              'white--text': selectedKitGauge && selectedScanGaugeIndex !== -1
            }"
          >
            <span class="text-center"> CONFIRM SELECTION </span>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "SelectedGauge",
  props: ["selectedKitGauge", "selectedScanGaugeIndex"]
}
</script>
