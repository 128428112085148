<template>
  <v-snackbar v-model="error" color="error" class="d-flex">
    <v-row align="center">
      <v-icon class="mx-3">mdi-alert</v-icon>
      {{ errorMessage }}
      <v-spacer />
      <v-btn icon @click="errorMessage = null">
        <v-icon> mdi-close </v-icon>
      </v-btn>
    </v-row>
  </v-snackbar>
</template>

<script>
export default {
  props: ["value"],

  computed: {
    errorMessage: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      }
    },

    error: {
      get() {
        return !!this.errorMessage
      },
      set(value) {
        if (!value) this.errorMessage = null
      }
    }
  }
}
</script>
