import GatewayAPI from "./GatewayAPI"
import InstanceAPI from "./InstanceAPI"
import MockClient from "./MockClient"

class HttpClient {
  constructor() {
    this.gateway = new GatewayAPI()
    this.instance = new InstanceAPI()
  }

  setTenantUid(uid) {
    this.gateway.setTenantUid(uid)
    this.instance.setTenantUid(uid)
    this.tenantUid = uid
  }

  setInstanceUrl(url) {
    this.instance.setInstanceUrl(url)
    this.instanceUrl = url
  }
}

var client
if (process.env.VUE_APP_MOCK_CLIENT) {
  client = new MockClient()
} else {
  client = new HttpClient()
}

export default client
