<template>
  <svg
    width="47"
    height="48"
    viewBox="0 0 47 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5272 7.09295L10.5806 8.28564L12.3565 11.1842L14.3031 9.99154L12.5272 7.09295Z"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M23.0837 2.61859C22.1329 1.06676 20.1042 0.579581 18.5524 1.53038L11.6938 5.73263L15.137 11.3522L21.9955 7.14994C23.5473 6.19914 24.0345 4.17037 23.0837 2.61859Z"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M31.6875 36.4557L20.54 18.2619C19.1834 16.0477 19.8786 13.153 22.0927 11.7964C24.3069 10.4397 27.2016 11.1349 28.5582 13.3491L44.8469 39.9339C46.0256 41.8577 45.1862 44.5169 42.972 45.8736C40.7578 47.2302 38.0074 46.7705 36.8287 44.8467L31.9882 36.9465"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.2168 13.5657L11.4687 9.73511"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20.9552 12.7146L18.7603 9.13223L21.2326 7.61743L23.4275 11.1998C23.0729 11.3063 22.6136 11.4819 22.1215 11.7795C21.6028 12.0931 21.2193 12.4385 20.9552 12.7146Z"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2.4375 7.54663L4.29183 10.5731"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.49922 8.19482L6.00586 10.2492"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.05437 12.0573L1 11.564"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M28.8346 13.8003C30.0029 15.7072 29.1552 18.3528 26.941 19.7094C24.7268 21.066 21.9848 20.62 20.8164 18.7131"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M41.4786 34.437C42.647 36.3439 41.7992 38.9895 39.585 40.3461C37.3709 41.7028 34.6288 41.2567 33.4604 39.3498"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M35.1569 24.1187C36.3252 26.0255 35.4774 28.6711 33.2633 30.0278C31.0491 31.3844 28.307 30.9383 27.1387 29.0314"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "SurgicalTool"
}
</script>
