<template>
  <v-row>
    <v-col>
      <input
        type="file"
        ref="fileInput"
        style="display: none"
        multiple
        @change="handleFileUpload"
      />
      Select message type:
      <v-btn-toggle v-model="visibility" class="rounded-20" elevation="0">
        <v-btn small value="LAB_ONLY">
          <v-icon small class="mr-1">mdi-note-outline</v-icon>
          Internal Note
        </v-btn>
        <!-- <v-btn small value="LAB_AND_DENTIST">
          <v-icon small class="mr-1">mdi-office-building-outline</v-icon>
          To Client
        </v-btn> -->
        <v-btn small value="LAB_AND_OSTEON" v-if="selectedOrder.forwarded">
          <v-img
            contain
            width="16"
            class="mr-1"
            :src="require('@/assets/osteon-logo.png')"
          />
          To Osteon
        </v-btn>
      </v-btn-toggle>
      <v-textarea
        v-if="visibility"
        v-model="note"
        class="rounded-10 mt-2"
        hide-details
        outlined
        dense
        rows="1"
        :placeholder="placeholder"
        auto-grow
        prepend-inner-icon="mdi-paperclip"
        @click:prepend-inner="openFileInput"
      >
        <template v-slot:append>
          <v-btn small icon :loading="sendingMessage" @click="sendMessage()">
            <v-icon v-if="visibility === 'LAB_ONLY'">
              mdi-content-save-outline
            </v-icon>
            <v-icon v-if="visibility !== 'LAB_ONLY'">mdi-send</v-icon>
          </v-btn>
        </template>
      </v-textarea>
    </v-col>
    <v-col v-if="attachments.length > 0" cols="12" align="end">
      <v-chip
        small
        v-for="(attachment, index) in attachments"
        :key="index"
        close
        class="ma-1 rounded-10"
        @click:close="removeAttachment(index)"
      >
        <v-icon small>mdi-paperclip</v-icon>
        <span class="text-truncate">
          {{ attachment.name }}
        </span>
      </v-chip>
    </v-col>
    <v-col cols="12" class="pt-1 pb-0" v-if="visibility">
      <v-alert
        dense
        outlined
        color="success"
        class="rounded-20"
        v-if="visibility === 'LAB_ONLY'"
      >
        <v-icon small color="success">mdi-check-outline</v-icon>
        Visible only to members of {{ currentTenant.name }}
      </v-alert>
      <v-alert
        dense
        outlined
        color="warning"
        class="rounded-20"
        v-if="visibility === 'LAB_AND_OSTEON'"
      >
        <v-icon small color="warning">mdi-alert-outline</v-icon>
        Visible to {{ currentTenant.name }} and Osteon
      </v-alert>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  props: ["sendingMessage"],

  data() {
    return {
      note: "",
      attachments: [],
      visibility: null
    }
  },

  computed: {
    ...mapGetters(["currentTenant", "selectedOrder"]),

    placeholder() {
      if (this.visibility === "LAB_ONLY") return "Internal Note"
      if (this.visibility === "LAB_AND_DENTIST") return "Message to Client"
      return "Message to Osteon"
    }
  },

  methods: {
    async sendMessage() {
      if (!this.note.length) {
        return
      }

      const message = {
        direction: "OUTBOUND",
        visibility: this.visibility,
        author_reference: this.currentTenant.name,
        recipient_reference: "",
        note: this.note
      }
      this.$emit("compose", message, this.attachments)
      this.reset()
    },

    removeAttachment(index) {
      this.attachments.splice(index, 1)
    },

    reset() {
      this.note = ""
      this.attachments = []
    },

    openFileInput() {
      this.$refs.fileInput.click()
    },

    handleFileUpload(event) {
      const files = [...event.target.files]
      files.forEach((file) => {
        this.attachments.push(file)
      })
    }
  }
}
</script>

<style>
.v-textarea.v-text-field--box.v-text-field--single-line.v-input--dense
  .v-input__prepend-inner,
.v-textarea.v-text-field--box.v-text-field--single-line.v-input--dense
  .v-input__prepend-outer,
.v-textarea.v-text-field--box.v-text-field--single-line.v-input--dense
  .v-input__append-inner,
.v-textarea.v-text-field--box.v-text-field--single-line.v-input--dense
  .v-input__append-outer,
.v-textarea.v-text-field--box.v-text-field--outlined.v-input--dense
  .v-input__prepend-inner,
.v-textarea.v-text-field--box.v-text-field--outlined.v-input--dense
  .v-input__prepend-outer,
.v-textarea.v-text-field--box.v-text-field--outlined.v-input--dense
  .v-input__append-inner,
.v-textarea.v-text-field--box.v-text-field--outlined.v-input--dense
  .v-input__append-outer,
.v-textarea.v-text-field--enclosed.v-text-field--single-line.v-input--dense
  .v-input__prepend-inner,
.v-textarea.v-text-field--enclosed.v-text-field--single-line.v-input--dense
  .v-input__prepend-outer,
.v-textarea.v-text-field--enclosed.v-text-field--single-line.v-input--dense
  .v-input__append-inner,
.v-textarea.v-text-field--enclosed.v-text-field--single-line.v-input--dense
  .v-input__append-outer,
.v-textarea.v-text-field--enclosed.v-text-field--outlined.v-input--dense
  .v-input__prepend-inner,
.v-textarea.v-text-field--enclosed.v-text-field--outlined.v-input--dense
  .v-input__prepend-outer,
.v-textarea.v-text-field--enclosed.v-text-field--outlined.v-input--dense
  .v-input__append-inner,
.v-textarea.v-text-field--enclosed.v-text-field--outlined.v-input--dense
  .v-input__append-outer {
  margin-top: 5px !important;
}
</style>
