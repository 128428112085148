<template>
  <v-card flat>
    <v-card-text class="pa-0">
      <v-form ref="basicInfoForm" v-model="valid" lazy-validation>
        <v-text-field
          autofocus
          v-model="form.firstName"
          required
          :rules="rules"
          outlined
          dense
          label="First name"
        />
        <v-text-field
          v-model="form.lastName"
          required
          :rules="rules"
          outlined
          dense
          label="Last name"
        />
        <v-text-field
          v-model="form.email"
          required
          :rules="[...rules, ...emailRule]"
          outlined
          dense
          type="email"
          label="Email"
          :disabled="idString === 'email'"
        />
        <PhoneInput v-model="form.phone" label="Phone" :country="country" />
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex"
import PhoneInput from "@/components/shared/PhoneInput.vue"

export default {
  name: "BasicInformation",

  props: ["value", "idString"],

  components: {
    PhoneInput
  },

  data() {
    return {
      valid: true,
      rules: [(v) => !!v || "Required"],
      emailRule: [
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/.test(v) ||
          "E-mail must be valid"
      ]
    }
  },

  computed: {
    ...mapGetters(["country"]),

    form: {
      get() {
        return this.value
      },

      set(value) {
        this.$emit("input", value)
      }
    }
  },

  methods: {
    validateForm() {
      return this.$refs.basicInfoForm.validate()
    }
  }
}
</script>
