var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"title-font"},[_vm._v(" CONNECTION TYPE "),_c('v-spacer'),(_vm.showErrors && !_vm.connectionType)?_c('div',{staticClass:"error--text text-body-2"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"error"}},[_vm._v("mdi-close-circle")]),_c('span',[_vm._v(" Please select a Connection Type ")])],1):_vm._e()],1),_c('v-card-text',[_vm._v(" Choose which type of connection the file will be designed to. ")]),_c('v-row',{staticClass:"mx-1 mb-2"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{directives:[{name:"ripple",rawName:"v-ripple"}],class:[
          'd-flex flex-row rounded-10 pa-2 fill-height btn-hover',
          { 'btn-selected': _vm.connectionType === 'direct' }
        ],staticStyle:{"user-select":"none","border-width":"2px"},attrs:{"outlined":""},on:{"click":function($event){return _vm.handleButtonSelect('direct')}}},[_c('v-img',{attrs:{"contain":"","src":require('@/assets/screw-comparison-rosen.png')}}),_c('v-card-text',{staticClass:"fill-height d-flex flex-column justify-center"},[_c('p',{staticClass:"mb-0"},[_c('strong',[_vm._v("Direct to Multi-Unit Abutment")])]),_c('p',{staticClass:"mb-0"},[_vm._v("with Tapered Screw seat (Rosen)")])])],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{directives:[{name:"ripple",rawName:"v-ripple"}],class:[
          'd-flex flex-row rounded-10 pa-2 fill-height btn-hover ',
          { 'btn-selected': _vm.connectionType === 'tiBase' }
        ],staticStyle:{"user-select":"none","border-width":"2px"},attrs:{"outlined":""},on:{"click":function($event){return _vm.handleButtonSelect('tiBase')}}},[_c('v-img',{attrs:{"contain":"","src":require('@/assets/screw-comparison-Ti-base.png')}}),_c('v-card-text',{staticClass:"fill-height d-flex flex-column justify-center"},[_c('p',{staticClass:"mb-0"},[_c('strong',[_vm._v("Ti-Base to Multi-Unit Abutment")])]),_c('p',{staticClass:"mb-0"},[_vm._v("with prosthetic screw seat")])])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }