<template>
  <v-row>
    <v-col>
      <v-card outlined class="rounded-10" max-width="300">
        <template v-if="approval.files.length">
          <v-card-text>
            <DesignViewer :approval-files="approval.files" />
          </v-card-text>
          <v-divider />
        </template>
        <template v-if="status === 'Pending'">
          <v-card-subtitle>
            Approval options, only use after carefully reviewing design.
          </v-card-subtitle>
          <v-card-text>
            <ApproveCase :approval="approval" />
            <ApproveWithChanges :approval="approval" />
            <RequestChanges :approval="approval" />
          </v-card-text>
          <v-divider />
        </template>
        <template v-if="approval.approval_due_at">
          <v-card-text class="d-flex">
            <small> Approval Due At: </small>
            <v-spacer />
            <small>
              {{ approval.approval_due_at | luxon({ output: "short" }) }}
            </small>
          </v-card-text>
          <v-divider />
        </template>
        <v-card-text class="d-flex">
          <small>Status: {{ status }}</small>
          <v-spacer />
          <small v-if="approval.cancelled_at">
            {{ approval.cancelled_at | luxon({ output: "short" }) }}
          </small>
          <small v-if="approval.approved_at">
            {{ approval.approved_at | luxon({ output: "short" }) }}
          </small>
        </v-card-text>
      </v-card>
      <small>
        <span v-if="approval.created_by">
          <v-icon small> mdi-account </v-icon>
          {{ approval.created_by }}
          -
        </span>
        {{ approval.created_at | luxon({ output: "short" }) }}
      </small>
    </v-col>
  </v-row>
</template>

<script>
import DesignViewer from "./ApprovalBubble/DesignViewer.vue"
import ApproveCase from "./ApprovalBubble/ApproveCase.vue"
import ApproveWithChanges from "./ApprovalBubble/ApproveWithChanges.vue"
import RequestChanges from "./ApprovalBubble/RequestChanges.vue"

export default {
  props: ["approval"],

  components: { DesignViewer, ApproveCase, ApproveWithChanges, RequestChanges },

  computed: {
    status() {
      if (this.approval.cancelled_at) return "Cancelled"
      if (this.approval.approved_at) return "Approved"
      return "Pending"
    }
  }
}
</script>
