<template>
  <v-card flat class="rounded-20">
    <v-card-title class="title-font pt-0">
      EXISTING EXPORT FILES
      <v-progress-circular class="ml-5" v-if="loading" indeterminate />
    </v-card-title>
    <v-card-text v-if="!selectedOrderExports?.length && !loading">
      There are no Existing Export Files.
    </v-card-text>
    <v-simple-table v-if="selectedOrderExports?.length">
      <template v-slot:default>
        <thead>
          <tr>
            <th>Scan Body</th>
            <th>Healing Cap</th>
            <th class="text-right">Exported At</th>
            <th class="text-right"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="exportItem in selectedOrderExports"
            :key="exportItem.uid"
            style="cursor: pointer"
            :class="{ 'gradient-bg-2': exportItem.uid === newExportUid }"
          >
            <td>
              {{ libraryName(exportItem.scan_body_component_id) }}
            </td>
            <td>
              {{ libraryName(exportItem.healing_cap_component_id) }}
            </td>
            <td class="text-right">
              {{ exportItem.created_at | luxon({ output: "short" }) }}
            </td>
            <td class="text-right">
              <v-btn
                class="btn-primary"
                small
                @click="downloadFiles(exportItem)"
              >
                Download
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import { delay } from "@/utils"

export default {
  name: "ExistingExports",

  props: ["value"],

  data() {
    return {
      timeout: null,
      loading: false
    }
  },

  computed: {
    ...mapGetters(["exportComponents", "selectedOrderExports"]),

    newExportUid: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit("input", val)
      }
    }
  },

  watch: {
    async newExportUid(val) {
      if (val) {
        if (!this.selectedOrderExports.find((ex) => ex.uid === val)) {
          await this.pollExport()
        }
        this.timeout = setTimeout(() => (this.newExportUid = null), 15000)
      }
    }
  },

  methods: {
    ...mapActions(["triggerReload"]),

    libraryName(componentId) {
      return this.exportComponents?.find((c) => c.id === parseInt(componentId))
        ?.name
    },

    downloadFiles(exportItem) {
      const scanBodyLink = document.createElement("a")
      scanBodyLink.href = exportItem.scan_body_file.url
      scanBodyLink.setAttribute("download", "")
      scanBodyLink.style.display = "none"
      document.body.appendChild(scanBodyLink)
      scanBodyLink.click()
      setTimeout(() => document.body.removeChild(scanBodyLink), 900)

      if (exportItem.healing_cap_file) {
        setTimeout(() => {
          const healingCapLink = document.createElement("a")
          healingCapLink.href = exportItem.healing_cap_file?.url
          healingCapLink.setAttribute("download", "")
          healingCapLink.style.display = "none"
          document.body.appendChild(healingCapLink)
          healingCapLink.click()
          setTimeout(() => {
            document.body.removeChild(healingCapLink)
          }, 900)
        }, 1000)
      }
    },

    async pollExport() {
      if (
        !this.selectedOrderExports.find((ex) => ex.uid === this.newExportUid)
      ) {
        this.loading = true
        await delay(1000)

        this.triggerReload("exports")
        return this.pollExport()
      }
      this.loading = false
    }
  },

  unmounted() {
    clearTimeout(this.timeout)
  }
}
</script>
