<template>
  <v-btn
    width="100%"
    height="86"
    v-ripple
    outlined
    :color="dragOver ? 'primary' : ''"
    class="rounded-10 fill-height py-1"
    style="
      cursor: pointer;
      user-select: none;
      background-color: #f5f5f5;
      border-color: #bbbbbb;
      border-width: 2px;
    "
    @drop.prevent="onDrop($event)"
    @dragover.prevent="dragOver = true"
    @dragenter.prevent="dragOver = true"
    @dragleave.prevent="dragOver = false"
    @click="addFile()"
  >
    <div class="d-flex flex-column">
      <v-progress-circular
        indeterminate
        v-if="loading"
        class="ml-4 mt-n2"
        style="position: absolute"
      />
      <span v-if="!loading"> UPLOAD {{ text }}</span>
      <i style="font-size: 10px; text-transform: none">
        {{ acceptFormats || "Any" }} file
      </i>
      <i
        v-if="invalidFormat"
        style="font-size: 10px; text-transform: none"
        class="red--text"
      >
        Invalid file format selected
      </i>
    </div>
  </v-btn>
</template>

<script>
export default {
  name: "NewOrderFileUploader",

  props: ["value", "text", "acceptFormats"],

  data() {
    return {
      loading: false,
      dragOver: false,
      invalidFormat: false,
      filesToUpload: [],
      inputDOMElement: null
    }
  },

  methods: {
    async addFile() {
      this.loading = true
      this.invalidFormat = false
      this.filesToUpload = []
      if (this.inputDOMElement) {
        this.inputDOMElement.value = ""
      } else {
        this.inputDOMElement = document.createElement("input")
        this.inputDOMElement.type = "file"
        this.inputDOMElement.multiple = true
        this.inputDOMElement.addEventListener("change", async (event) => {
          let files = [...event.target.files]
          files.forEach((file) => {
            if (!this.fileExtensionFilter(file)) {
              this.invalidFormat = true
              return
            }
            this.filesToUpload.push(file)
          })

          this.$emit("input", this.filesToUpload)
        })
      }
      this.inputDOMElement.click()
      this.loading = false
    },

    async onDrop(event) {
      this.loading = true
      this.dragOver = false
      this.invalidFormat = false
      this.filesToUpload = []
      let files = [...event.dataTransfer.files]
      files.forEach((file) => {
        if (!this.fileExtensionFilter(file)) {
          this.invalidFormat = true
          return
        }
        this.filesToUpload.push(file)
      })

      this.$emit("input", this.filesToUpload)
      this.loading = false
    },

    fileExtensionFilter(file) {
      if (!this.acceptFormats || !this.acceptFormats.length) {
        return true
      }
      return this.acceptFormats.includes(
        file.name.toLowerCase().split(".").pop()
      )
    }
  }
}
</script>
