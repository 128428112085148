<template>
  <v-card flat class="rounded-20">
    <v-card-title class="title-font"> SCAN GAUGE SCAN ALIGNMENT </v-card-title>

    <v-card-text
      class="text-center pb-0 mx-auto"
      style="width: 500px; height: 300px"
    >
      <v-progress-circular
        v-if="loading"
        indeterminate
        size="128"
        class="mt-12"
      />
      <div ref="view"></div>
    </v-card-text>

    <v-card-text>
      <v-row v-if="showHeatmapColors">
        <v-col>
          <HeatmapScale class="mx-auto" />
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-text class="pa-0">
      <v-card flat class="rounded-20">
        <v-card-actions>
          <v-switch
            dense
            inset
            label="Show Heatmap"
            v-model="showHeatmapColors"
            :disabled="loading"
            class="ml-10"
          />
          <v-spacer />
          <v-switch
            dense
            inset
            label="Show Tissue"
            v-model="showTissue"
            :disabled="loading"
            class="mr-10"
          />
        </v-card-actions>
        <v-card-text class="text-center">
          <v-btn
            text
            small
            class="btn-primary"
            @click="$emit('toggleViewLibAssociation')"
          >
            <v-icon class="mr-2">$gauge</v-icon>
            START LIBRARY ASSOCIATION
          </v-btn>
        </v-card-text>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex"
import apiClient from "@/lib/ApiClient"
import * as NCFrontend from "@osteon-nexus-connect-lib/nc-frontend"
import HeatmapScale from "./AlignmentScan/HeatmapScale.vue"

export default {
  name: "AlignmentScan",

  props: ["order"],

  components: { HeatmapScale },

  view: null,

  data() {
    return {
      loading: false,
      showHeatmapColors: false,
      showTissue: true
    }
  },

  computed: {
    ...mapGetters(["token", "selectedOrder"])
  },

  watch: {
    showHeatmapColors() {
      this.toggleHeatmap()
    },

    showTissue() {
      this.toggleTissue()
    }
  },

  methods: {
    async getNcSession() {
      // Could set session response in store, call it on load of any order view tab?
      // Could conditionally fetch if session response not in store.
      this.loading = true
      const sessionResponse = await NCFrontend.ApiClient.sessionGet(
        `${apiClient.instanceUrl}/${apiClient.tenantUid}/validation`,
        this.$axios.defaults.headers.Authorization,
        this.selectedOrder.nc_session_id
      )

      if (!this.$refs.view) return

      this.view = NCFrontend.createView("overviewView", this.$refs.view)
      NCFrontend.add(this.view)
      this.view.setVisualisation(
        new NCFrontend.Visualisations.LibraryAssociation(this.view.element, {
          fixedScan: sessionResponse.fixedScan,
          scanAnalysis: sessionResponse.scanAnalysis,
          immediateCase: this.selectedOrder.immediate
        })
      )
      this.loading = false
    },

    toggleHeatmap() {
      if (this.showHeatmapColors) {
        this.view.visualisation.showHeatmapColors()
      } else {
        this.view.visualisation.showScoreColors()
      }
    },

    toggleTissue() {
      this.view.visualisation.setScanTissueVisible(this.showTissue)
    }
  },

  mounted() {
    this.getNcSession()
  }
}
</script>
