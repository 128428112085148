<template>
  <div>
    <v-card flat class="mx-4 rounded-10">
      <v-data-table
        :headers="filteredHeaders"
        :items="users || []"
        hide-default-footer
        class="rounded-10"
      >
        <template v-slot:no-data v-if="loading">
          <v-progress-circular indeterminate />
        </template>
        <template v-slot:[`item.name`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small v-bind="attrs" v-on="on" class="mr-1">
                {{ item.notify ? "mdi-bell-ring" : "mdi-bell-off" }}
              </v-icon>
            </template>
            <span>Notifications {{ item.notify ? "On" : "Off" }}</span>
          </v-tooltip>
          {{ item.firstName + " " + item.lastName }}
        </template>
        <template v-slot:[`item.role`]="{ item }">
          {{ capitalise(item.role) }}
        </template>
        <template v-slot:[`item.notify`]="{ item }">
          <v-checkbox
            v-model="item.notify"
            @click="toggleNotify(item)"
            :disabled="disableNotifyToggle(item)"
          />
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="editUser(item)"
            v-if="isMutable(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteUser(item)" v-if="isMutable(item)">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>

      <v-dialog v-model="dialog" max-width="550px" content-class="rounded-20">
        <v-card>
          <v-toolbar flat color="off">
            <v-toolbar-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-toolbar-title>
          </v-toolbar>

          <v-card-text class="pb-0 pt-5">
            <v-form ref="userForm">
              <v-row>
                <v-col cols="12" sm="8">
                  <v-text-field
                    v-model="editedUser.firstName"
                    label="First Name"
                    outlined
                    dense
                    :rules="rules"
                  />
                  <v-text-field
                    v-model="editedUser.lastName"
                    label="Last Name"
                    outlined
                    dense
                    :rules="rules"
                  />
                  <v-text-field
                    v-model="editedUser.email"
                    label="Email"
                    outlined
                    dense
                  />
                  <PhoneInput
                    v-model="editedUser.phoneNumber"
                    label="Phone Number"
                    :country="country"
                  />
                </v-col>
                <v-col cols="12" sm="4">
                  <v-select
                    :items="[
                      { text: 'Owner', value: 'owner' },
                      { text: 'Member', value: 'member' }
                    ]"
                    :menu-props="{ rounded: '10' }"
                    label="Role"
                    v-model="editedUser.role"
                    outlined
                    dense
                    :rules="rules"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>

          <v-card-actions class="off">
            <v-spacer></v-spacer>
            <v-btn class="btn-error" @click="close"> Cancel </v-btn>
            <v-btn class="btn-primary" @click="save"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogDelete"
        max-width="500px"
        content-class="rounded-20"
      >
        <v-card>
          <v-card-title class="text-h5">
            Are you sure you want to delete this item?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="btn-error" @click="closeDelete"> Cancel </v-btn>
            <v-btn class="btn-primary" @click="deleteItemConfirm"> OK </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
    <v-card-actions class="mx-2">
      <v-spacer></v-spacer>
      <v-btn
        v-if="currentTenant.role === 'owner'"
        @click="dialog = true"
        class="btn-primary"
        text
      >
        Add User</v-btn
      >
    </v-card-actions>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import { capitalise } from "@/utils"
import client from "@/lib/ApiClient"
import PhoneInput from "@/components/shared/PhoneInput.vue"

export default {
  name: "AuthorizedTenantUsers",

  components: { PhoneInput },

  data() {
    return {
      loading: false,
      dialog: false,
      editedIndex: -1,
      dialogDelete: false,
      rules: [(v) => !!v],
      editedUser: {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        role: ""
      },
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name"
        },
        {
          text: "Email",
          sortable: false,
          value: "email"
        },
        {
          text: "Phone Number",
          sortable: false,
          value: "phoneNumber"
        },
        {
          text: "Role",
          sortable: false,
          value: "role"
        },
        {
          text: "Notify",
          sortable: false,
          value: "notify"
        },
        {
          text: "Actions",
          sortable: false,
          value: "action"
        }
      ],
      capitalise
    }
  },

  watch: {
    users(val) {
      if (val) this.loading = false
    },

    dialog(val) {
      val || this.close()
    },

    dialogDelete(val) {
      val || this.closeDelete()
    }
  },

  computed: {
    ...mapGetters(["currentTenant", "signUpSession", "country", "users", "me"]),

    filteredHeaders() {
      if (this.currentTenant.role === "owner") return this.headers
      else return this.headers.filter((h) => h.value !== "action")
    },

    formTitle() {
      return this.editedIndex === -1 ? "New User" : "Edit User"
    }
  },

  methods: {
    ...mapActions(["triggerReload"]),

    toggleNotify(user) {
      client.gateway.tenants.toggleNotify(user.userUid)
    },

    disableNotifyToggle(user) {
      if (
        this.currentTenant.role === "member" &&
        !this.me.id_strings.includes(user.email)
      )
        return true
      if (
        user.role === "owner" &&
        user.notify &&
        this.users.filter((u) => u.role === "owner" && u.notify).length === 1
      )
        return true
      return false
    },

    isMutable(user) {
      if (this.currentTenant.role === "member") return false
      if (this.me.id_strings.includes(user.email)) return false

      // if (
      //   user.role === "owner" &&
      //   this.users.filter((item) => item.role === "owner").length === 1
      // )
      //   return false

      if (
        user.role === "owner" &&
        user.notify &&
        this.users.filter((item) => item.role === "owner" && item.notify)
          .length === 1
      ) {
        return false
      }

      return true
    },

    editUser(item) {
      this.editedIndex = this.users.indexOf(item)
      this.editedUser = Object.assign({}, item)
      this.dialog = true
    },

    deleteUser(item) {
      this.editedIndex = this.users.indexOf(item)
      this.editedUser = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      if (
        this.editedUser.role === "owner" &&
        this.users.filter((item) => item.role === "owner").length === 1
      ) {
        // Communicate error state to user?
        this.dialogDelete = false
        return
      }
      this.users.splice(this.editedIndex, 1)

      client.gateway.tenants.deleteRole(this.editedUser.userUid)
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$refs.userForm.resetValidation()
      this.$nextTick(() => {
        this.editedUser = Object.assign(
          {},
          { name: "", email: "", phoneNumber: "", role: "" }
        )
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedUser = Object.assign(
          {},
          { name: "", email: "", phoneNumber: "", role: "" }
        )
        this.editedIndex = -1
      })
    },

    async save() {
      if (
        !this.editedUser.firstName ||
        !this.editedUser.lastName ||
        (!this.editedUser.phoneNumber && !this.editedUser.email) ||
        !this.editedUser.role
      )
        return this.$refs.userForm.validate()

      if (this.editedIndex > -1) {
        Object.assign(this.users[this.editedIndex], this.editedUser)
        client.gateway.tenants.modifyRole(
          {
            first_name: this.editedUser.firstName,
            last_name: this.editedUser.lastName,
            email_address: this.editedUser.email || null,
            phone_number: this.editedUser.phoneNumber || null,
            role: this.editedUser.role.toLowerCase()
          },
          this.editedUser.userUid
        )
      } else {
        const user = await client.gateway.tenants.addRole({
          tenant_uid: this.currentTenant.uid,
          first_name: this.editedUser.firstName,
          last_name: this.editedUser.lastName,
          email_address: this.editedUser.email || null,
          phone_number: this.editedUser.phoneNumber || null,
          role: this.editedUser.role.toLowerCase()
        })
        this.editedUser.userUid = user.user_uid
        this.users.push(this.editedUser)
      }
      this.close()
    }
  },

  async beforeMount() {
    this.loading = true
    if (!this.users) this.triggerReload("users")
    else this.loading = false
  }
}
</script>
