<template>
  <v-row>
    <v-col :cols="workflowStep > 1 ? 6 : 12">
      <v-card flat class="rounded-20 mx-4">
        <v-row class="ma-0">
          <v-col cols="8" class="pa-0">
            <v-card-title class="title-font">
              <v-icon large class="mr-2">$toothdesigns</v-icon> DESIGN ONLY
              ORDER
              <v-progress-circular class="ml-5" v-if="loading" indeterminate />
            </v-card-title>
          </v-col>
          <v-col cols="4" class="pa-0">
            <v-card-text class="fill-width d-flex justify-center pb-0">
              <v-img
                :src="require('@/assets/design-only-prosthesis.png')"
                class="mx-4"
                contain
                max-width="200"
              />
            </v-card-text>
          </v-col>
        </v-row>
        <v-card-text
          v-if="workflowStep === 0 && ready"
          class="black--text py-0"
        >
          <v-stepper vertical class="elevation-0 py-0" v-model="currentStep">
            <v-stepper-step
              step="1"
              :complete="exportComplete"
              :color="exportComplete ? 'success' : 'primary'"
            >
              Export Abutment Position
            </v-stepper-step>
            <v-stepper-content step="1">
              <p>
                In order to order a milled bar you will need to supply us with a
                tooth design.
              </p>
              <p>
                You have yet to export abutment positions for this case. Please
                continue to export abutment positions.
              </p>
              <v-btn class="btn-primary" @click="$emit('toExport')">
                Export Abutment Position
              </v-btn>
            </v-stepper-content>
            <v-stepper-step step="2"> Order Tooth Design </v-stepper-step>
            <v-stepper-content step="2">
              <v-btn
                class="rounded-10 btn-success white--text my-4 mx-4"
                @click="handleContinue"
                :disabled="loading"
              >
                Order Design Now
              </v-btn>
            </v-stepper-content>
            <v-stepper-step step="3"> Approve Bar Design </v-stepper-step>
            <v-stepper-content step="3" />
            <v-stepper-step step="4"> Download Design </v-stepper-step>
            <v-stepper-content step="4" />
          </v-stepper>
        </v-card-text>

        <v-card-text v-if="workflowStep === 1" class="pt-0">
          Continue your order by completing the necessary options.
        </v-card-text>

        <v-card-text v-if="workflowStep === 2" class="pt-0">
          <OrderSummary />
        </v-card-text>

        <div v-if="workflowStep === 0">
          <v-card-actions>
            <v-spacer />
            <v-btn class="btn-error" @click="$emit('back')"> Back </v-btn>
          </v-card-actions>
        </div>
        <div v-if="workflowStep === 1">
          <ForwardedOrderOptions
            @back="handleStepChange(--workflowStep)"
            @next="handleStepChange(++workflowStep)"
          />
        </div>
      </v-card>
    </v-col>
    <v-col v-if="workflowStep > 1">
      <CaseChat />
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import ForwardedOrderOptions from "./DesignOnlyOrder/ForwardedOrderOptions"
import OrderSummary from "./DesignOnlyOrder/OrderSummary"
import CaseChat from "@/components/shared/CaseChat"
import client from "@/lib/ApiClient"

export default {
  name: "DesignOnlyOrder",

  components: {
    CaseChat,
    ForwardedOrderOptions,
    OrderSummary
  },

  data() {
    return {
      loading: false,
      workflowStep: 0,
      dialog: false,
      ready: false
    }
  },

  computed: {
    ...mapGetters([
      "selectedOrder",
      "paymentMethods",
      "selectedForwardedOrder",
      "forwardedOrderForm",
      "selectedOrderExports"
    ]),

    currentStep() {
      return this.exportComplete ? 2 : 1
    },

    exportComplete() {
      return !!this.selectedOrderExports?.length
    },

    acquisitionMethod() {
      return this.acquisitionStrategy?.find((s) => s.product_code === "DESIGN")
        .method
    }
  },

  methods: {
    ...mapActions([
      "setSelectedForwardedOrder",
      "setForwardedOrderForm",
      "setCurrentQuotas",
      "setPaymentMethods"
    ]),

    handleStepChange(step) {
      this.workflowStep = step
    },

    async createForwardedOrder() {
      const forwardedOrder = await client.instance.orders.createForwardedOrder(
        this.selectedOrder.uid,
        this.forwardedOrderForm
      )
      this.setSelectedForwardedOrder(forwardedOrder)
    },

    async handleContinue() {
      this.loading = true
      await this.createForwardedOrder()
      this.workflowStep++
      this.loading = false
    }
  },

  async mounted() {
    if (this.selectedForwardedOrder) {
      if (this.selectedForwardedOrder.submitted_at) {
        this.handleStepChange(2)
      } else {
        this.handleStepChange(1)
      }
    } else {
      this.setForwardedOrderForm({
        ...this.forwardedOrderForm,
        implants: [
          ...this.selectedOrder.responses.find(
            (item) => item.widget === "tooth_picker"
          ).value
        ]
      })
    }
    this.ready = true
  }
}
</script>
