<template>
  <v-dialog
    v-model="dialog"
    :max-width="!!clientData.uid ? '950px' : '550px'"
    content-class="rounded-20"
    persistent
  >
    <v-card>
      <v-card-title class="off">
        <span class="title-font text-uppercase">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text class="pb-0 pt-5">
        <v-row>
          <v-col :cols="!!clientData.uid ? 6 : 12">
            <v-form ref="form" lazy-validation>
              <v-text-field
                v-model="clientData.name"
                label="Clinic Name*"
                :rules="rule"
                outlined
                hide-details
                class="mb-3"
                dense
              />
              <v-text-field
                v-model="clientData.contact"
                label="Contact Name*"
                :rules="rule"
                outlined
                hide-details
                class="mb-3"
                dense
              />
              <v-text-field
                v-model="clientData.email"
                label="Email*"
                :rules="rule"
                outlined
                hide-details
                class="mb-3"
                dense
              />
              <PhoneInput
                v-model="clientData.phone"
                label="Phone Number"
                :country="country"
              />
              <v-text-field
                v-model="clientData.address_line_1"
                :rules="rule"
                class="mb-3"
                outlined
                dense
                hide-details
                label="Address 1*"
              />
              <v-text-field
                v-model="clientData.address_line_2"
                class="mb-3"
                outlined
                dense
                hide-details
                label="Address 2"
              />

              <v-row class="pb-0">
                <v-col class="pr-1" cols="7">
                  <v-text-field
                    v-model="clientData.suburb"
                    :rules="rule"
                    class="mb-3"
                    outlined
                    dense
                    hide-details
                    label="City / Suburb*"
                  />
                </v-col>
                <v-col class="pl-1">
                  <v-text-field
                    v-model="clientData.post_code"
                    :rules="rule"
                    class="mb-3"
                    outlined
                    dense
                    hide-details
                    label="Post Code*"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pr-1 pt-0 pb-0" cols="7">
                  <v-autocomplete
                    outlined
                    dense
                    label="Country*"
                    v-model="clientData.country"
                    :rules="rule"
                    :items="countryItems"
                  />
                </v-col>
                <v-col class="pl-1 pt-0 pb-0">
                  <v-text-field
                    v-model="clientData.state"
                    class="mb-3"
                    outlined
                    dense
                    hide-details
                    label="State"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-col>
          <v-col cols="6" v-if="!!clientData.uid">
            <v-card flat>
              <v-card-title>
                <h2 class="title-h2">Authorized Identities</h2>
              </v-card-title>
              <v-card-text>
                <v-list>
                  <v-list-item
                    v-for="(id, index) in client.id_strings"
                    :key="index"
                  >
                    <v-list-item-icon>
                      <v-icon v-if="idType(id) === 'phone'">
                        mdi-cellphone
                      </v-icon>
                      <v-icon v-if="idType(id) === 'email'"> mdi-email </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                      {{ id.id_string }}
                    </v-list-item-title>
                    <v-list-item-action>
                      <v-btn icon @click="removeIdentity(id)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item @click="addIdentity = true">
                    <v-list-item-icon>
                      <v-icon> mdi-plus </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title> Add Identity </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-dialog
          max-width="550px"
          v-model="addIdentity"
          content-class="rounded-20"
        >
          <v-card rounded>
            <v-card-title> Add Authorized Identity </v-card-title>
            <v-card-text>
              <v-tabs v-model="identityTab" class="mb-5">
                <v-tab>
                  <v-icon class="mr-2"> mdi-email </v-icon>
                  Email
                </v-tab>
                <v-tab>
                  <v-icon class="mr-2"> mdi-cellphone </v-icon>
                  Phone
                </v-tab>
              </v-tabs>
              <v-text-field
                v-if="identityTab === 0"
                v-model="identityField"
                label="Email"
                :rules="rule"
                outlined
                hide-details
                class="mb-5"
                dense
              />
              <v-form>
                <PhoneInput
                  v-if="identityTab === 1"
                  v-model="identityField"
                  label="Phone Number"
                  :country="country"
                />
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn class="btn-primary" @click="saveIdentity"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-text>

      <v-card-actions class="off">
        <v-spacer />
        <v-btn
          color="error"
          class="rounded-10"
          text
          @click="close"
          :disabled="loading"
        >
          Cancel
        </v-btn>
        <v-btn class="btn-primary" @click="save" :loading="loading">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import client from "@/lib/ApiClient"
import PhoneInput from "../shared/PhoneInput.vue"
import { mapGetters } from "vuex"

export default {
  name: "ClientModal",

  props: ["value", "client", "formTitle", "loading"],

  components: { PhoneInput },

  data() {
    return {
      countryList: [],
      rule: [(v) => !!v || "Required"],
      newClient: {
        name: "",
        contact: "",
        email_address: "",
        phone: "",
        address_line_1: "",
        address_line_2: "",
        suburb: "",
        state: "",
        post_code: "",
        country: ""
      },
      addIdentity: false,
      identityTab: 0,
      identityField: null
    }
  },

  watch: {
    identityTab() {
      this.identityField = null
    },

    value(val) {
      if (!val) this.$refs.form.resetValidation()
    }
  },

  computed: {
    ...mapGetters(["country"]),
    dialog: {
      get() {
        return this.value
      },

      set(value) {
        this.$emit("input", value)
      }
    },

    clientData: {
      get() {
        return this.client || this.newClient
      },

      set() {
        this.$emit("client")
      }
    },

    countryItems() {
      if (!this.countryList.length) {
        return []
      }
      return this.countryList.map((item) => {
        return {
          text: item.name,
          value: item.code
        }
      })
    }
  },

  methods: {
    save() {
      if (!this.$refs.form.validate()) {
        return
      }
      this.$emit("save")
    },

    close() {
      this.$emit("close")
    },

    async fetchCountries() {
      this.countryList = await client.gateway.tenants.fetchCountries()
    },

    idType(id) {
      if (id.id_string.startsWith("+")) return "phone"
      return "email"
    },

    async saveIdentity() {
      this.$emit("addIdentity", { id_string: this.identityField })
      this.addIdentity = false
    },

    async removeIdentity(id) {
      this.$emit("removeIdentity", id.uid)
      this.addIdentity = false
    }
  },

  mounted() {
    this.fetchCountries()
  }
}
</script>
