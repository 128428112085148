<template>
  <v-card
    flat
    class="ml-4 d-flex flex-column align-center justify-center rounded-20"
    height="100%"
  >
    <v-card-title>
      <div class="mx-auto">
        <div class="text-center mb-2">
          <v-progress-circular indeterminate size="64" v-if="loading" />
          <v-icon size="64" color="success" v-if="associationPass && !loading">
            mdi-check-circle
          </v-icon>
          <v-icon size="64" color="error" v-if="!associationPass && !loading">
            mdi-close-circle
          </v-icon>
        </div>
        <div>
          Scan Gauge Association
          <span v-if="associationPass && !loading"> Completed </span>
          <span v-if="!associationPass && !loading"> Failed </span>
        </div>
      </div>
    </v-card-title>
    <v-card-text class="text-center">
      If you want to redo the scan gauge library assoication, click on the redo
      association button below
    </v-card-text>
    <v-card-actions>
      <v-btn small text class="btn-primary mx-auto" @click="$emit('redo')">
        <v-icon class="mr-2">mdi-restore</v-icon>
        Redo Association
      </v-btn>
      <v-btn
        small
        text
        class="btn-primary ml-3"
        :to="{ query: { tab: 'export' } }"
      >
        <v-icon class="mr-2">mdi-download</v-icon>
        Download Export
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "AssociationResult",

  props: ["associationPass", "loading"]
}
</script>
