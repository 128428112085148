<template>
  <v-card flat v-if="pricing" class="rounded-10">
    <v-card-title class="pb-0 text-center primary--text">
      <v-row>
        <v-col class="text-center primary--text">
          <h3>
            {{ pricing.name }}
            <v-btn
              fab
              elevation="0"
              x-small
              dark
              color="grey"
              class="right-corner"
              @click="collapsed = !collapsed"
            >
              <v-icon>
                {{ collapsed ? "mdi-chevron-down" : "mdi-chevron-up" }}
              </v-icon>
            </v-btn>
          </h3>
          <span style="font-size: 20px">
            {{ pricing.description }}
          </span>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <component
        :is="priceAndButtonComponent"
        @selection="onClick"
        :pricing="pricing"
        :change="change"
        :returnButton="returnButton"
        :loading="loading"
      />
      <v-expand-transition>
        <ul style="list-style-type: none" v-if="!collapsed" class="py-1">
          <li class="my-1" v-for="(feature, index) in features" :key="index">
            <span v-if="feature.value">
              <v-icon class="mr-2" small color="success"
                >mdi-checkbox-marked-circle</v-icon
              >
              <span v-html="feature.text"></span>
            </span>
            <span v-if="!feature.value">
              <v-icon class="mr-2" small color="error">mdi-close-circle</v-icon>
              <span>{{ feature.text }}</span>
            </span>
          </li>
        </ul>
      </v-expand-transition>
    </v-card-text>
  </v-card>
</template>

<script>
import { toTitleCase } from "@/utils"

import PricingVariant from "./PricingCard/PricingVariant.vue"
import ChangeVariant from "./PricingCard/ChangeVariant.vue"
export default {
  name: "PricingCard",

  components: {
    PricingVariant,
    ChangeVariant
  },

  props: ["pricing", "returnButton", "step", "change", "loading"],

  watch: {
    step(value) {
      if (value === 3) {
        this.collapsed = true
        return
      }
      this.collapsed = false
    }
  },

  data() {
    return {
      collapsed: false
    }
  },

  computed: {
    signUp() {
      if (!this.pricing && !this.pricing.signUpButton) {
        return
      }
      return this.pricing.signUpButton
    },

    features() {
      if (!this.pricing?.features) {
        return []
      }
      const features = [
        {
          text: "Scan Analyser Tool",
          value: true
        }
      ]
      Object.entries(this.pricing.features).forEach(([key, value]) => {
        if (key === "inbox") {
          features.push({
            text: "Nexus Connect Inbox (CMS)",
            value
          })
        }
        if (key === "ordering") {
          features.push(
            {
              text: "Client Ordering Platform",
              value
            },
            {
              text: "Client File Transfer Platform",
              value
            }
          )
        }
        if (key === "messaging") {
          features.push({
            text: "Client Communication Portal",
            value
          })
        }
        if (key === "approvals") {
          features.push({
            text: "Client Proposal Management",
            value
          })
        }
        if (key === "custom_branding") {
          features.push(
            {
              text: "Custom Branding",
              value
            },
            {
              text: "Branded Ordering Platform",
              value
            },
            {
              text: "Branded File Transfer Platform",
              value
            }
          )
        }
        if (key === "api") {
          features.push({
            text: "API Access to Nexus Connect",
            value
          })
        }
        if (key === "exports") {
          const exports = this.pricing?.quotas?.find(
            (q) => q.code === "exports"
          )
          if (exports) {
            features.push({
              text: `<strong>${
                exports.included
              }x</strong> Implant Position Export${
                exports.included > 1 ? "s" : ""
              }`,
              value
            })
          }
        }
        if (key === "bars") {
          const bars = this.pricing?.quotas?.find((q) => q.code === "bars")
          if (bars) {
            features.push({
              text: `<strong>${bars.included}x</strong> Milled Nexus Bar${
                bars.included > 1 ? "s" : ""
              }`,
              value
            })
          }
        }
        if (key === "designs") {
          const designs = this.pricing?.quotas?.find(
            (q) => q.code === "designs"
          )
          if (designs) {
            features.push({
              text: `<strong>${designs.included}x</strong> Case Design${
                designs.included > 1 ? "s" : ""
              }`,
              value
            })
          }
        }
        if (key === "registered_kits") {
          const kits = this.pricing?.quotas?.find(
            (q) => q.code === "registered_kits"
          )
          if (kits) {
            features.push({
              text: `Supports up to <strong>${
                kits.included
              }</strong> Nexus IOS Scan Gauge Kit${
                kits.included > 1 ? "s" : ""
              }`,
              value
            })
          }
        }
      })
      return features
    },

    priceAndButtonComponent() {
      return this.change ? ChangeVariant : PricingVariant
    }
  },

  methods: {
    toTitleCase(string) {
      return toTitleCase(string)
    },

    onClick() {
      this.$emit("selected", this.pricing.code)
    }
  }
}
</script>
