<template>
  <div>
    <v-card flat class="pt-0">
      <v-row class="pa-0 ma-0" align-items="center">
        <v-card-title class="px-0 pt-0 title-font">
          <v-icon class="mr-2">mdi-map-marker</v-icon>
          Billing Address
        </v-card-title>
        <v-spacer />
        <v-checkbox
          v-model="form.sameShippingAddress"
          dense
          label="Use same address for shipping"
          hide-details
          class="mt-0"
        />
      </v-row>
    </v-card>
    <v-text-field
      v-model="form.billing_address_1"
      required
      class="mb-3"
      :rules="rules"
      outlined
      dense
      hide-details
      label="Address 1 *"
    />
    <v-text-field
      v-model="form.billing_address_2"
      required
      class="mb-3"
      outlined
      dense
      hide-details
      label="Address 2"
    />

    <v-row class="pb-0">
      <v-col class="pr-1" cols="7">
        <v-text-field
          v-model="form.billing_suburb"
          required
          class="mb-3"
          :rules="rules"
          outlined
          dense
          hide-details
          label="City / Suburb *"
        />
      </v-col>
      <v-col class="pl-1">
        <v-text-field
          v-model="form.billing_postal_code"
          :rules="rules"
          required
          class="mb-3"
          outlined
          dense
          hide-details
          label="Post Code *"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pr-1 pt-0 pb-0" cols="7">
        <v-autocomplete
          outlined
          dense
          label="Country *"
          v-model="form.billing_country"
          :rules="rules"
          :items="country"
          :item-text="country.text"
          :item-value="country.value"
        />
      </v-col>
      <v-col class="pl-1 pt-0 pb-0">
        <v-text-field
          v-model="form.billing_state"
          required
          class="mb-3"
          outlined
          dense
          hide-details
          label="State"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import client from "@/lib/ApiClient"

export default {
  name: "BillingAddress",

  props: ["value"],

  data() {
    return {
      countryList: [],
      rules: [(v) => !!v || "Required"]
    }
  },

  computed: {
    form() {
      return this.value
    },

    country() {
      return this.countryList.map((item) => {
        return {
          text: item.name,
          value: item.code
        }
      })
    }
  },

  methods: {
    validateForm() {
      return this.$refs.businessInfoForm.validate()
    }
  },

  async mounted() {
    this.countryList = await client.gateway.tenants.fetchCountries()
  }
}
</script>
